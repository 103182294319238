import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-educacional-i',
  templateUrl: './educacional-i.component.html',
  styleUrls: ['./educacional-i.component.css']
})
export class EducacionalIComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
