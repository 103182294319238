import {Component, OnInit} from '@angular/core';
import {ManutencaoPreventivaHttpService} from '../../../../services/http/manutencao-preventiva-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-manutencao-preventiva',
  templateUrl: './manutencao-preventiva.component.html',
  styleUrls: ['./manutencao-preventiva.component.css']
})
export class ManutencaoPreventivaComponent implements OnInit {

  dados = [];

  constructor(private _http: ManutencaoPreventivaHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
