import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AuthService} from '../../../../services/auth.service';
import {AvaliacaoDesempenhoHttpService} from '../../../../services/http/avaliacao-desempenho-http.service';
import {AvaliacaoDesempenhoModalEComponent} from '../avaliacao-desempenho-modal-e/avaliacao-desempenho-modal-e.component';
import {AvaliacaoDesempenhoModalDComponent} from '../avaliacao-desempenho-modal-d/avaliacao-desempenho-modal-d.component';

declare var $: any;

@Component({
  selector: 'app-avaliacao-desempenho-c',
  templateUrl: './avaliacao-desempenho-c.component.html',
  styleUrls: ['./avaliacao-desempenho-c.component.css']
})
export class AvaliacaoDesempenhoCComponent implements OnInit {

  dados = [];
  formData: FormGroup;
  registroId: string;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(AvaliacaoDesempenhoModalEComponent) modalE: AvaliacaoDesempenhoModalEComponent;
  @ViewChild(AvaliacaoDesempenhoModalDComponent) modalD: AvaliacaoDesempenhoModalDComponent;

  constructor(private fb: FormBuilder,
              private _http: AvaliacaoDesempenhoHttpService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService) {

  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  ngOnInit() {
    $(function () {
      $('#ano1,#ano2,#ano3,#ano4').mask('00/0000');

    });
    this.listarDados();
    this.formFields();
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarDados();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarDados() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.dados = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  private formFields() {
    this.formData = this.fb.group({
      tipo_acreditacao: [null, [Validators.required]],
      escopo_atividade: [null, [Validators.required]],
      portaria: [null],
      ano1: [null, [Validators.maxLength(7)]],
      ano2: [null, [Validators.maxLength(7)]],
      ano3: [null, [Validators.maxLength(7)]],
      ano4: [null, [Validators.maxLength(7)]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }
}
