import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsLocaleService} from 'ngx-bootstrap';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {InstalacaoEquipamentoHttpService} from '../../../../services/http/instalacao-equipamento-http.service';
import {AuthService} from '../../../../services/auth.service';
import {InstalacaoEquipamentoModalEComponent} from '../instalacao-equipamento-modal-e/instalacao-equipamento-modal-e.component';
import {InstalacaoEquipamentoModalDComponent} from '../instalacao-equipamento-modal-d/instalacao-equipamento-modal-d.component';
import {Observable} from 'rxjs';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';
import {map} from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-instalacao-equipamento-c',
  templateUrl: './instalacao-equipamento-c.component.html',
  styleUrls: ['./instalacao-equipamento-c.component.css']
})
export class InstalacaoEquipamentoCComponent implements OnInit {

  formInstalacao: FormGroup;
  instalacoes = [];
  dadosPessoal: Observable<Array<any>>; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
  registroId: string;
  pessoaId: string;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(InstalacaoEquipamentoModalEComponent) modalE: InstalacaoEquipamentoModalEComponent;
  @ViewChild(InstalacaoEquipamentoModalDComponent) modalD: InstalacaoEquipamentoModalDComponent;

  constructor(private fb: FormBuilder,
              private datepicker: BsLocaleService,
              private notifyMsg: NotifyMessageService,
              private _http: InstalacaoEquipamentoHttpService,
              private _httpPessoal: PessoalHttpService,
              private authService: AuthService) {
    datepicker.use('pt-BR');
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  ngOnInit() {
    $(function () {
      $('#data').mask('00/00/0000');
    });

    this.formFields();
    this.listarInstalacoes();
    this.dadosPessoal = this._httpPessoal.index().pipe(map(resp => resp.data));
  }

  onSubmitInsert() {
    if (this.formInstalacao.valid) {
      this._http.store(this.formInstalacao.value)
        .subscribe(() => {
          this.formInstalacao.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarInstalacoes();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarInstalacoes() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.instalacoes = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarInstalacoes();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  private formFields() {
    this.formInstalacao = this.fb.group({
      data: [null, [Validators.required]],
      condicao_instalacao: [null, [Validators.required]],
      condicao_equipamento: [null, [Validators.required]],
      responsavel: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarInstalacoes();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarInstalacoes();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }

  /*comobox responsavel*/
  getPessoaId(id) {
    this.pessoaId = id.value;
  }
}
