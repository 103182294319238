import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {DocumentacaoPessoalHttpService} from '../../../../services/http/documentacao-pessoal-http.service';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ModalXlComponent} from '../../../bootstrap/modal-xl/modal-xl.component';

@Component({
    selector: 'app-documentacao-pessoal-modal-e',
    templateUrl: './documentacao-pessoal-modal-e.component.html',
    styleUrls: ['./documentacao-pessoal-modal-e.component.css']
})
export class DocumentacaoPessoalModalEComponent implements OnInit {

    @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

    @ViewChild(ModalXlComponent) modal: ModalXlComponent;

    formData: FormGroup;

    dadosPessoal: Observable<Array<any>>; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/

    _registroId: string;

    @Input()
    set registroId(value) {
        if (value !== undefined) {
            this._registroId = value;
            this._http.show(value).subscribe((resp) => {
                    this.formData.patchValue(resp.data);
                }
            );
        }
    }

    constructor(private fb: FormBuilder,
                private _http: DocumentacaoPessoalHttpService,
                private _httpPessoal: PessoalHttpService,
                private notifyMsg: NotifyMessageService) {
    }

    ngOnInit() {
        this.formFields();
        //this._httpPessoal.index().subscribe((resp) => this.dadosPessoal = resp.data);
        this.dadosPessoal = this._httpPessoal.index().pipe(map(resp => resp.data));
    }

    onSubmitUpdate() {
        this._http.update(this._registroId, this.formData.value)
            .subscribe(() => {
                this.modal.hide();
                this.onSuccess.emit();
            }, error => this.notifyMsg.error(error.error.message));
    }

    showModal() {
        this.modal.show();
    }

    private formFields() {
        this.formData = this.fb.group({
            pessoal_id: [null, [Validators.required]],
            curriculo: [null, [Validators.required]],
            contrato: [null, [Validators.required]],
            tipo_rg_cnh: [null],
            d_vencimento_rg_cnh: [null],
            // crea_carteira: [null, [Validators.required]],
            // d_cnh_rg: [null],
            d_crea_certidao: [null],
            d_aso: [null],
            imparcialidade: [null, [Validators.required]],
            habilitacao: [null, [Validators.required]],
            conduta: [null, [Validators.required]],
            epi: [null, [Validators.required]]
        });
    }

    // private formFields() {
    //   this.formData = this.fb.group({
    //     pessoal_id: [null, [Validators.required]],
    //     anexo_curriculo: [null],
    //     anexo_contrato: [null],
    //     anexo_cnh_rg: [null],
    //     anexo_crea_carteira: [null],
    //     anexo_crea_certidao: [null],
    //     d_aso: [null],
    //     anexo_imparcialidade: [null],
    //     anexo_habilitacao: [null],
    //     anexo_conduta: [null],
    //     anexo_epi: [null]
    //   });
    // }

}
