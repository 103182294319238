import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsLocaleService} from 'ngx-bootstrap';
import {CalibracaoVerificacaoHttpService} from '../../../../services/http/calibracao-verificacao-http.service';
import {Router} from '@angular/router';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AuthService} from '../../../../services/auth.service';
import {CalibracaoVerificacaoModalCComponent} from '../calibracao-verificacao-modal-c/calibracao-verificacao-modal-c.component';
import {CalibracaoVerificacaoModalDComponent} from '../calibracao-verificacao-modal-d/calibracao-verificacao-modal-d.component';

declare var $: any;

@Component({
  selector: 'app-calibracao-verificacao-create',
  templateUrl: './calibracao-verificacao-create.component.html',
  styleUrls: ['./calibracao-verificacao-create.component.css']
})
export class CalibracaoVerificacaoCreateComponent implements OnInit {

  calibracoes = [];
  formCalibracao: FormGroup;

  /*apenas p/ edição*/
  registroId: string;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(CalibracaoVerificacaoModalCComponent) modalE: CalibracaoVerificacaoModalCComponent;
  @ViewChild(CalibracaoVerificacaoModalDComponent) modalD: CalibracaoVerificacaoModalDComponent;

  constructor(private fb: FormBuilder,
              private datepicker: BsLocaleService,
              private _http: CalibracaoVerificacaoHttpService,
              private router: Router,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService) {
    datepicker.use('pt-BR');
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  ngOnInit() {
    $(function () {
      $('#d_vencimento').mask('00/00/0000');
    });

    this.formFields();
    this.listarDados();
  }

  onSubmitInsert() {
    if (this.formCalibracao.valid) {
      this._http.store(this.formCalibracao.value)
        .subscribe(() => {
          this.formCalibracao.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarDados();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarDados() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.calibracoes = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  private formFields() {
    this.formCalibracao = this.fb.group({
      codigo: [null],
      equipamento: [null, [Validators.required]],
      frequencia: [null, [Validators.required]],
      vencimento: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluido com sucesso.');
  }
}
