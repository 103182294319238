import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalComponent} from '../../../bootstrap/modal/modal.component';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AnaliseProcessoHttpService} from '../../../../services/http/analise-processo-http.service';

@Component({
  selector: 'app-analise-processo-modal-d',
  templateUrl: './analise-processo-modal-d.component.html',
  styleUrls: ['./analise-processo-modal-d.component.css']
})
export class AnaliseProcessoModalDComponent implements OnInit {

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  @ViewChild(ModalComponent) modal: ModalComponent;

  //@Input()
  //registroId: string;

  _registroId: string;
  dados = <any>[];

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this._http.show(value).subscribe((resp) => this.dados = resp);
    }
  }

  constructor(private _http: AnaliseProcessoHttpService,
              private notifyMsg: NotifyMessageService) {
  }

  ngOnInit() {
  }

  showModal() {
    this.modal.show();
  }

  destroy() {
    this._http.destroy(this._registroId).subscribe(() => {
      this.modal.hide();
      this.onSuccess.emit();
    }, error => this.notifyMsg.error(error.error.message));
  }

}
