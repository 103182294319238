import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnexosEmpresaHttpService {

  search: string;
  private baseUrl = `${environment.api.url}/anexar_doc_empresa`;

  constructor(private http: HttpClient) {
  }

  index(): Observable<any> {
    return this.http.get(this.baseUrl);
  }

  show(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  showporempresa(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/empresa/${id}`);
  }

  create(empresaId, gerenciadorId, files: FileList): Observable<any> {
    const formData = new FormData();
    const filesArray = Array.from(files);

    filesArray.forEach((file) => {
      formData.append('anexos[]', file);
      formData.append('empresaId', empresaId);
      formData.append('gerenciadorId', gerenciadorId);
    });

    return this.http.post<any>(this.baseUrl, formData);
  }


}
