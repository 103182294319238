import {Component, OnInit} from '@angular/core';
import {PendenciaHttpService} from '../../../../services/http/pendencia-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-pendencia',
  templateUrl: './pendencia.component.html',
  styleUrls: ['./pendencia.component.css']
})
export class PendenciaComponent implements OnInit {

  dados = [];

  constructor(private _http: PendenciaHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => {
        this.dados = resp.data;
      });
  }
}
