import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {

  }

  /* responsavel por proteger as páginas caso não seja valido o token */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isAuth = this.authService.isAuth();
    this.redirectIfUnauthenticated(isAuth);
    return this.authService.isAuth();
  }

  private redirectIfUnauthenticated(isAuth: boolean) {
    if (!isAuth) {
      this.router.navigate(['login']);
    }
  }
}
