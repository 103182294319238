import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { EmpresaHttpService } from "../../../../services/http/empresa-http.service";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import { CidadesHttpService } from "../../../../services/http/cidades-http.service";

declare var $: any;

@Component({
  selector: "app-empresa-edit",
  templateUrl: "./empresa-edit.component.html",
  styleUrls: ["./empresa-edit.component.css"],
})
export class EmpresaEditComponent implements OnInit {
  id: string;
  formEmpresa: FormGroup;

  constructor(
    private _http: EmpresaHttpService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notifyMsg: NotifyMessageService,
    private cidadesHttp: CidadesHttpService
  ) {}

  ngOnInit() {
    $(function () {
      $("#cnpj").mask("99.999.999/9999-99");
      $("#telefone").mask("(99)9999-9999");
      $("#celular").mask("(99)99999-9999");
    });

    this.getId();
    this.showFormEdit();
    this.formFields();
  }

  private getId() {
    return this.activatedRoute.params.subscribe((res) => {
      this.id = atob(res.id); /* pega o id passado na url e descriptando*/
    });
  }

  showFormEdit() {
    this._http.show(this.id).subscribe((response) => {
      this.formEmpresa.patchValue(response.data);
    });
  }

  onSubmitUpdate() {
    this._http.update(this.id, this.formEmpresa.value).subscribe(
      () => {
        this.notifyMsg.success("Registro atualizado com sucesso.");
        return this.router.navigate(["/empresa"]);
      },
      (error) => this.notifyMsg.error(error.error.message)
    );
  }

  private formFields() {
    this.formEmpresa = this.fb.group({
      cnpj: [null, [Validators.required, Validators.maxLength(18)]],
      razao_social: [null, [Validators.required, Validators.maxLength(150)]],
      nome_fantasia: [null, [Validators.required, Validators.maxLength(150)]],
      numero_oia: [null, [Validators.required]],
      acreditacao: [null, [Validators.required]],
      independencia: [null, [Validators.required]],
      // endereco: [null, [Validators.required]],
      // numero: [null],
      // bairro: [null, [Validators.required]],
      // cidade: [null, [Validators.required]],
      telefone: [null],
      celular: [null],
      email: [null],
      site: [null],
    });
  }

  getIdUf(value: any) {
    this.listarCidades(value);
  }

  private listarCidades(uf) {
    /*Dessa forma se o subscribe() quando estiver usa | async, la no select,
     * é preciso tbm, declarar a variavel do tipo Observable*/
    // @ts-ignore
    this.cidades = this.cidadesHttp.getCidades(uf);
  }
}
