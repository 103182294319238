import {Component, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {SessaoHttpService} from '../../../services/http/sessao-http.service';
import * as moment from 'moment';

@Component({
  selector: 'app-empresa-ativa',
  templateUrl: './empresa-ativa.component.html',
  styleUrls: ['./empresa-ativa.component.css']
})
export class EmpresaAtivaComponent implements OnInit {

  registroId: string;
  visita: string;
  diaHoje: string;
  empresaLogada: string;

  constructor(public authService: AuthService,
              private _httpSessao: SessaoHttpService) {
  }

  ngOnInit() {
    this.registroId = this.authService.getEmpresa();
    this._httpSessao.ultimoAcessoCompany(this.registroId).subscribe((resp) => {
      this.visita = resp.visita;
    });
    this._httpSessao.diaHoje().subscribe((resp) => {
      this.diaHoje = resp.diahoje;
    });
    this._httpSessao.companyLog(this.registroId).subscribe((resp) => {
      this.empresaLogada = resp.nome_fantasia;
    });
  }

  // tesData() {
  //   let d = new Date();
  //   return moment(d).format('DD/MM/YYYY');
  // }
}
