import {Component, OnInit} from '@angular/core';
import {ObservacoesHttpService} from '../../../../services/http/observacoes-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-observacoes',
  templateUrl: './observacoes.component.html',
  styleUrls: ['./observacoes.component.css']
})
export class ObservacoesComponent implements OnInit {

  observacoes = [];

  constructor(protected _http: ObservacoesHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => {
      this.observacoes = resp.data;
    });
  }


}
