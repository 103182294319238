import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {EscolherEmpresaModalComponent} from '../escolher-empresa-modal/escolher-empresa-modal.component';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-refresh-empresa',
  templateUrl: './refresh-empresa.component.html',
  styleUrls: ['./refresh-empresa.component.css']
})
export class RefreshEmpresaComponent implements OnInit {

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(EscolherEmpresaModalComponent) modalEmpresa: EscolherEmpresaModalComponent;

  constructor(private router: Router,
              private authService: AuthService) {
  }

  ngOnInit() {
    if (!this.authService.getEmpresa()) {
      this.modalEmpresa.showModal();
    }

    // if (!this.authService.getEmpresa()) {
    //   this.modalEmpresa.showModal();
    // } else {
    //   this.authService.setEmpresa(null);
    // }

  }

  onSuccessAtivo($event: any) {
    this.router.navigate(['/dashboard']);
    // setTimeout(() => {
    //   this.router.navigate(['/dashboard']);
    // }, 500);
  }
}
