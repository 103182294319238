import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EmpresaHttpService } from "../../../../services/http/empresa-http.service";
import { Router } from "@angular/router";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import { CidadesHttpService } from "../../../../services/http/cidades-http.service";
import { Observable } from "rxjs";

declare var $: any;

@Component({
  selector: "app-empresa-create",
  templateUrl: "./empresa-create.component.html",
  styleUrls: ["./empresa-create.component.css"],
})
export class EmpresaCreateComponent implements OnInit {
  formEmpresa: FormGroup;
  cidades: Observable<Array<any>>;

  constructor(
    private fb: FormBuilder,
    private _http: EmpresaHttpService,
    private router: Router,
    private notifyMsg: NotifyMessageService,
    private cidadesHttp: CidadesHttpService
  ) {}

  ngOnInit() {
    $(function () {
      $("#cnpj").mask("99.999.999/9999-99");
      $("#telefone").mask("(99)9999-9999");
      $("#celular").mask("(99)99999-9999");
    });

    this.formFields();
  }

  onSubmitInsert() {
    this._http.store(this.formEmpresa.value).subscribe(
      () => {
        this.formEmpresa.reset();
        this.notifyMsg.success("Registro incluido com sucesso.");
        return this.router.navigate(["/empresa"]);
      },
      (error) => this.notifyMsg.error(error.error.message)
    );
  }

  private formFields() {
    this.formEmpresa = this.fb.group({
      cnpj: [null, [Validators.required, Validators.maxLength(18)]],
      razao_social: [null, [Validators.required, Validators.maxLength(150)]],
      nome_fantasia: [null, [Validators.required, Validators.maxLength(150)]],
      numero_oia: [null, [Validators.required]],
      acreditacao: [null, [Validators.required]],
      independencia: [null, [Validators.required]],
      endereco: [null, [Validators.required]],
      numero: [null],
      bairro: [null, [Validators.required]],
      cidade: [null, [Validators.required]],
      telefone: [null],
      celular: [null],
      email: [null],
      site: [null],
    });
  }

  getIdUf(value: any) {
    this.listarCidades(value);
  }

  private listarCidades(uf) {
    /*dessa forma sem o subscribe() quando estiver la no select usar (| async).
     * é preciso tbm, declarar a variavel do tipo Observable*/
    // @ts-ignore
    this.cidades = this.cidadesHttp.getCidades(uf);
  }

  aviso(value) {
    if (value === undefined) {
      this.notifyMsg.notice("Por favor, selecione o estado.");
    }
  }
}
