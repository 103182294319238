import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {UsersHttpService} from '../../../../services/http/users-http.service';
import {EmpresaHttpService} from '../../../../services/http/empresa-http.service';
import {AuthService} from '../../../../services/auth.service';
import {Select2OptionData} from 'ng2-select2';

declare var $: any;

@Component({
  selector: 'app-gerenciador-modal-e',
  templateUrl: './gerenciador-modal-e.component.html',
  styleUrls: ['./gerenciador-modal-e.component.css']
})
export class GerenciadorModalEComponent implements OnInit {

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  formData: FormGroup;
  empresa = [];
  selectEmpresa: Array<Select2OptionData> = [];
  _registroId: string;

  empresasData: Array<Select2OptionData> = [];
  options: Select2Options;
  public value: string[] = [];

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      // Na resposta
      this._http.show(value).subscribe((resp) => {
        // Popula o array com as empresas do usuário
        if(resp&&resp.data){
          this.formData.patchValue(resp.data);
          if (Array.isArray(resp.data.empresas)) {
            this.value = resp.data.empresas.map(({id})=>String(id)).filter(id => id != null);
          }
        }
      });
    }
  }

  constructor(private fb: FormBuilder,
              private _http: UsersHttpService,
              private _httpEmpresa: EmpresaHttpService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.options = {
      multiple: true
    }
    
    this.formFields();
    $(function () {
      $('#celular').mask('(99)99999-9999');
    });
    this._httpEmpresa.index().subscribe((resp) => {
      if(resp&&Array.isArray(resp.data)){
        this.empresasData = resp.data.map(({id,nome_fantasia})=>({id:String(id),text:String(nome_fantasia)})).filter(empresa => empresa != null);
      }
    });
  }

  onSubmitUpdate() {
    
    this.formData.value.selectEmpresa = this.value;
    

    this._http.update(this._registroId, this.formData.value)
      .subscribe(() => {
        this.formData.reset();
        this.modal.hide();
        this.onSuccess.emit();
        window.location.href = '/gerenciador';
      }, error => this.notifyMsg.error(error.message));

  }

  showModal() {
    this.modal.show();
  }

  changed(data: {value: string[]}) {
    this.value = data.value;
  }

  private formFields() {
    this.formData = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      cargo: [null, [Validators.required]],
      celular: [null],
      login: [null, [Validators.required]],
      nivel: [null, [Validators.required]],
      status: [1, [Validators.required]],
      selectEmpresa: [null, [Validators.required]]
    });
  }

  infor() {
    alert('Master: Gestor do sistema que possui todos os recursos, responsável por cadastrar novas contas, bloquear acessos, emitir comunicações gerais, entre outras atividades.\n\n' +
      'Mentor: Responsável por validar os anexos (verificar se os preenchimentos dos formulários estão corretos), editar as informações de atendimento aos critérios, as informações e orientações da súmula, entre outras atividades.\n\n' +
      'Consultor: Acesso para verificar as informações e os anexos dos seus clientes, registrar mudanças significativas, orientar o organismo, entre outras atividades.\n\n' +
      'Cliente: Verificar as informações postadas pelo mentor e pelo consultor, acompanhar o atendimento aos critérios de acreditação, anexar os arquivos gerados no período, registrar questionamentos ou explicações a questionamentos feitos, entre outras atividades...');
  }

}
