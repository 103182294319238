import {Component, OnInit} from '@angular/core';
import {ReclamacaoApelacaoHttpService} from '../../../../services/http/reclamacao-apelacao-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-reclamacao-apelacao',
  templateUrl: './reclamacao-apelacao.component.html',
  styleUrls: ['./reclamacao-apelacao.component.css']
})
export class ReclamacaoApelacaoComponent implements OnInit {

  dados = [];

  constructor(private _http: ReclamacaoApelacaoHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
