import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsLocaleService} from 'ngx-bootstrap';

declare var $: any;

@Component({
  selector: 'app-form-teste',
  templateUrl: './form-teste.component.html',
  styleUrls: ['./form-teste.component.css']
})
export class FormTesteComponent implements OnInit {

  // minDate: Date;
  // maxDate: Date;

  formCliente: FormGroup;

  constructor(private fb: FormBuilder,
              private datepicker: BsLocaleService) {
    datepicker.use('pt-BR');

    // this.minDate = new Date();
    // this.maxDate = new Date();
    // this.minDate.setDate(this.minDate.getDate() - 1);
    // this.maxDate.setDate(this.maxDate.getDate() + 7);

    let now = moment(); // add this 2 of 4
    //console.log('hello world', now.format()); // add this 3 of 4
    //console.log(now.add(7, 'days').format()); // add this 4of 4
  }

  ngOnInit() {
    $(function () {
      $('#cpf').mask('000.000.000-00', {reverse: true});
      $('#celular').mask('00000-0000');
      $('#d_nascimento').mask('00/00/0000');
      $('#n_valor').priceFormat({
        prefix: '',
        centsSeparator: ',',
        thousandsSeparator: '.',
        limit: 17,
        centsLimit: 2
      });
    });

    this.formFields();
  }

  onSubmitInsert() {
  }

  private formFields() {
    this.formCliente = this.fb.group({
      cpf: [null, [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      rg: [null],
      nome: [null],
      email: [null],
      celular: [null],
      login: [null],
      senha: [null],
      n_valor: [null],
      d_nascimento: [new Date().toLocaleDateString('pt-BR')],
      //d_nascimento: [moment().format('YYYY-MM-DD')],
      d_data_limite: [null]
    });
  }

}

// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
