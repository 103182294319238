import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { MultiAnexosMdPDFComponent } from "../multi-anexos-md-pdf/multi-anexos-md-pdf.component";
import { AuthService } from "../../../../services/auth.service";
import { MultiAnexosHttpService } from "../../../../services/http/multi-anexos-http.service";
import { MultiAnexosMdEComponent } from "../multi-anexos-md-e/multi-anexos-md-e.component";
import { MultiAnexosInvalidadosMdDComponent } from "../multi-anexos-invalidados-md-d/multi-anexos-invalidados-md-d.component";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "app-dados-vencidos",
  templateUrl: "./dados-vencidos.component.html",
  styleUrls: ["./dados-vencidos.component.css"],
})
export class DadosVencidosComponent implements OnInit {
  registrosVencidos = <any>[];
  empresa_id: any;
  loading: boolean;
  registroId: string;

  regex = /(?:\.([^.]+))?$/;
  validExtensions = ["PDF", "DOCX", "DOC", "XLS", "XLSX"];

  @Input() router: Router;
  @Input() notifyMsg: NotifyMessageService;
  @Output("listAnexos") listAnexos: EventEmitter<any> = new EventEmitter();
  @ViewChild(MultiAnexosMdEComponent) modalE: MultiAnexosMdEComponent;
  @ViewChild(MultiAnexosInvalidadosMdDComponent)
  modalInvalidoD: MultiAnexosInvalidadosMdDComponent;
  @ViewChild(MultiAnexosMdPDFComponent) modalPDF: MultiAnexosMdPDFComponent;

  constructor(
    private _http: MultiAnexosHttpService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.dadosVencidos();
  }

  dadosVencidos() {
    this.loading = true;
    this.empresa_id = { value: this.authService.getEmpresa(), disabled: false };
    this._http.filterVencidos(this.empresa_id.value).subscribe((resp) => {
      this.loading = false;

      this.registrosVencidos = resp.data.map((r) => {
        r.data_lembrete = "";

        if (
          r.periodo_lembrete !== undefined &&
          r.data_proxima_acao !== undefined
        ) {
          r.data_lembrete = moment(r.data_proxima_acao, "YYYY-MM-DD")
            .subtract(r.periodo_lembrete, "d")
            .format("YYYY-MM-DD");

          if (moment().isSameOrBefore(r.data_lembrete)) {
            r.action_needed = "Em dia";
          } else if (
            moment().isBetween(
              r.data_lembrete,
              moment(r.data_proxima_acao).add(1, "d")
            )
          ) {
            r.action_needed = "A vencer";
          } else {
            r.action_needed = "Vencido";
          }
        }

        return r;
      });

      // Ordena Vencidos > A Vencer
      this.registrosVencidos.sort((a, b) => {
        switch (a.action_needed) {
          case "A vencer":
            return 1;
          default:
            return -1;
        }
      });
    });
  }

  showUpdatePedente(id_base64: any) {
    this.registroId = id_base64;
    this.modalE.showModal();
  }

  showPDF(id_base64: any) {
    this.registroId = id_base64;
    this.modalPDF.showModal();
  }

  showAnexoReplace(anexo_id: any) {
    console.log(anexo_id);
    const path = `/anexos/substituir/${anexo_id}`
    //  let route = this.router.config.find((r) => r.path === path);
    // route.data = { param_anexo_id: anexo_id };
    this.router.navigateByUrl(path);
  }

  showAnexoDel(id_base64: any) {
    console.log("showAnexoDel", id_base64);
    this.registroId = id_base64;
    this.modalInvalidoD.showModal();
  }

  onSuccessDeleteInvalido($event: any) {
    this.dadosVencidos();
    this.notifyMsg.success("Registro excluído com sucesso.");
    this.listAnexos.emit();
  }

  onSuccessUpdatePedente($event: any) {
    console.log("DADOS VENCIDOS: onSuccessUpdatePedente");
    this.dadosVencidos();
    this.listAnexos.emit();
  }
}
