import {Component, OnInit} from '@angular/core';
import {CalibracaoVerificacaoHttpService} from '../../../../services/http/calibracao-verificacao-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-calibracao-verificacao',
  templateUrl: './calibracao-verificacao.component.html',
  styleUrls: ['./calibracao-verificacao.component.css']
})
export class CalibracaoVerificacaoComponent implements OnInit {

  dados = [];

  constructor(private _http: CalibracaoVerificacaoHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
