import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PessoalHttpService} from '../../../services/http/pessoal-http.service';

@Component({
  selector: 'app-combo-responsavel',
  templateUrl: './combo-responsavel.component.html',
  styleUrls: ['./combo-responsavel.component.css']
})
export class ComboResponsavelComponent implements OnInit {

  dados = [];

  @Output() valueCombo: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _http: PessoalHttpService) {
  }

  ngOnInit() {
    this._http.index().subscribe((resp) => this.dados = resp.data);
  }

  getCompo(value: any) {
    this.valueCombo.emit(value);
  }
}
