import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";

declare const $;

@Component({
  selector: "app-modal-xl",
  templateUrl: "./modal-xl.component.html",
  styleUrls: ["./modal-xl.component.css"],
})
export class ModalXlComponent implements OnInit {
  @Output()
  onHide: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(private element: ElementRef) {}

  ngOnInit() {
    const jqueryElement = this.getjQueryElement();

    jqueryElement.find("[modal-title]").addClass("modal-title");
    jqueryElement.find("[modal-body]").addClass("modal-body");
    jqueryElement.find("[modal-footer]").addClass("modal-footer");

    /*
       Trabalhando com events do modal do bootstrap
       https://getbootstrap.com/docs/4.1/components/modal/#events
     */
    jqueryElement.on("hidden.bs.modal", (e) => {
      this.onHide.emit(e);
    });
  }

  show() {
    this.getjQueryElement().modal("show");
  }

  hide() {
    this.getjQueryElement().modal("hide");
  }

  private getjQueryElement() {
    const nativeElement = this.element.nativeElement;
    return $(nativeElement.firstChild);
  }
}
