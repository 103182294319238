import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {EmpresaHttpService} from '../../../services/http/empresa-http.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalComponent} from '../../bootstrap/modal/modal.component';
import {AuthService} from '../../../services/auth.service';
import {NotifyMessageService} from '../../../services/notify-message.service';
import {SessaoHttpService} from '../../../services/http/sessao-http.service';
import { Select2OptionData } from 'ng2-select2';
@Component({
  selector: 'app-escolher-empresa-modal',
  templateUrl: './escolher-empresa-modal.component.html',
  styleUrls: ['./escolher-empresa-modal.component.css']
})
export class EscolherEmpresaModalComponent implements OnInit {

  formData: FormGroup;
  empresas = [];
  public empresasList: Array<Select2OptionData>;
  options: Select2Options;
  selected: string;

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(ModalComponent) modal: ModalComponent;

  constructor(private fb: FormBuilder,
              private _http: EmpresaHttpService,
              private _httpSessao: SessaoHttpService,
              private authService: AuthService,
              private notifyMsg: NotifyMessageService) {
  }

  ngOnInit() {
    this.options = {
      placeholder: 'Escolha a empresa',
      width: '466',
      minimumResultsForSearch: Infinity
    }

    this.formFields();
    this._http.index().subscribe((resp) => {
      this.empresasList = resp.data.map(({id,nome_fantasia,num_pendencias}) => ({id: String(id), text: "("+String(num_pendencias)+") "+String(nome_fantasia)}));
    });
  }

  public changed(e: any): void {
    this.selected = e.value;
    
  }

  onSubmit() {
      this.authService.setEmpresa(this.selected);
      this.modal.hide();
      /*gravar sessão do cliente*/
      this._httpSessao.store({'user_id': this.authService.profile.id, 'empresa_id': this.authService.getEmpresa()}).subscribe();
      this.onSuccess.emit();
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    this.formData = this.fb.group({
      empresa: [null, [Validators.required]]
    });
  }

  hideModal($event: Event) {
    if (!this.authService.getEmpresa()) {
      this.notifyMsg.notice('Ops.. por favor selecione a empresa.');
      this.modal.show();
    }
  }
}
