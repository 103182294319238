import {Component, OnInit} from '@angular/core';
import {ProgramacaoHttpService} from '../../../../services/http/programacao-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-programacao',
  templateUrl: './programacao.component.html',
  styleUrls: ['./programacao.component.css']
})
export class ProgramacaoComponent implements OnInit {

  dados = [];

  constructor(private _http: ProgramacaoHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
