import {Component, OnInit} from '@angular/core';
import {MapaAtividadeHttpService} from '../../../../services/http/mapa-atividade-http.service';
import {MapaValidacaoHttpService} from '../../../../services/http/mapa-validacao-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-mapa-qualidade',
  templateUrl: './mapa-qualidade.component.html',
  styleUrls: ['./mapa-qualidade.component.css']
})
export class MapaQualidadeComponent implements OnInit {

  dadosMpAtividade = [];
  dadosMpValidacao = [];

  constructor(private _httpMpAtividade: MapaAtividadeHttpService,
              private _httpMpValidacao: MapaValidacaoHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._httpMpAtividade.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dadosMpAtividade = resp.data);

    this._httpMpValidacao.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dadosMpValidacao = resp.data);
  }

}
