import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import { GruposHttpService } from "../../../../services/http/grupos-http.service";
import { SubGruposHttpService } from "../../../../services/http/sub-grupos-http.service";
import { AuthService } from "../../../../services/auth.service";
import { SubGruposModalEComponent } from "../sub-grupos-modal-e/sub-grupos-modal-e.component";
import { SubGruposModalDComponent } from "../sub-grupos-modal-d/sub-grupos-modal-d.component";
import { Observable } from "rxjs";

declare var $: any;

@Component({
  selector: "app-sub-grupos-c",
  templateUrl: "./sub-grupos-c.component.html",
  styleUrls: ["./sub-grupos-c.component.css"],
})
export class SubGruposCComponent implements OnInit {
  dados = [];
  grupos: Observable<Array<any>>;
  formData: FormGroup;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(SubGruposModalEComponent) modalUpdate: SubGruposModalEComponent;
  @ViewChild(SubGruposModalDComponent) modalD: SubGruposModalDComponent;

  constructor(
    private fb: FormBuilder,
    private _http: SubGruposHttpService,
    private _httpGrupos: GruposHttpService,
    private notifyMsg: NotifyMessageService,
    private authService: AuthService
  ) {}

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  /*apenas p/ edição*/
  registroId: string;

  ngOnInit() {
    this.getGrupos();
    this.listarDados();
    this.formFields();
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value).subscribe(
        () => {
          this.formData.reset({
            /*limpa todos os outros inputs, menos os listados abaixo*/
            empresa_id: { value: this.ids.empresaId, disabled: false },
            users_id: { value: this.ids.gerenciadorId, disabled: false },
          });
          this.notifyMsg.success("Registro incluido com sucesso.");
          this.listarDados();
        },
        (error) => this.notifyMsg.error(error.error.message)
      );
    } else {
      this.notifyMsg.info("Por favor preencha os campos obrigatórios.");
    }
  }

  listarDados() {
    this._http.index().subscribe((resp) => {
      this.dados = resp;
    });
  }

  getGrupos() {
    this._httpGrupos.index().subscribe((resp) => {
      this.grupos = resp.sort(function (a, b) {
        if (a.descricao < b.descricao) {
          return -1;
        }
        if (a.descricao > b.descricao) {
          return 1;
        }
        return 0;
      });
    });
  }

  showEdit(id: any) {
    this.registroId = id;
    //console.log(this.registroId);
    this.modalUpdate.showModal();
  }

  showDel(id: string) {
    this.registroId = id;
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  private formFields() {
    this.formData = this.fb.group({
      grupo_id: [null, [Validators.required]],
      descricao: [null, [Validators.required]],
      instrucoes: [null],
      dt_prox_acao_obrigatorio: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]],
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success("Registro atualizado com sucesso.");
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success("Registro excluído com sucesso.");
  }
}
