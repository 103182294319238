import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { SelectAnexoGrupoHttpService } from "../../../../services/http/select-anexo-grupo-http.service";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import { SelectAnexoSubgrupoHttpService } from "../../../../services/http/select-anexo-subgrupo-http.service";
import { map } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../../../services/auth.service";
import { MultiAnexosHttpService } from "../../../../services/http/multi-anexos-http.service";
import { AnexoValidoMdComponent } from "../anexo-valido-md/anexo-valido-md.component";
import { MultiAnexosMdDComponent } from "../multi-anexos-md-d/multi-anexos-md-d.component";
import { MultiAnexosMdEComponent } from "../multi-anexos-md-e/multi-anexos-md-e.component";
import { MultiAnexosMdPDFComponent } from "../multi-anexos-md-pdf/multi-anexos-md-pdf.component";
import { MultiAnexosInvalidadosMdDComponent } from "../multi-anexos-invalidados-md-d/multi-anexos-invalidados-md-d.component";
import { MultiAnexosInvalidadosMdEComponent } from "../multi-anexos-invalidados-md-e/multi-anexos-invalidados-md-e.component";
import { NgxSpinnerService } from "ngx-spinner";
import { MultiAnexosUpdateDocMdEComponent } from "../multi-anexos-update-doc-md-e/multi-anexos-update-doc-md-e.component";
import * as moment from "moment";

@Component({
  selector: "app-multi-anexos-va",
  templateUrl: "./multi-anexos-va.component.html",
  styleUrls: ["./multi-anexos-va.component.css"],
})
export class MultiAnexosPendentesInvalidadoComponent implements OnInit {
  dadosGrupo: Observable<Array<any>>;
  dadosSubgrupo: Observable<Array<any>>;
  dadosP = [];
  dadosI = [];
  dadosV = [];

  getGrupoId: number;
  getTitle: any;
  registroId: string;
  subGrupo: any;
  subGrupoInstrucoes: string;
  subGrupoDtProxAcaoObrigatoria: boolean;
  observacao: string;

  regex = /(?:\.([^.]+))?$/;
  validExtensions = ["PDF", "DOCX", "DOC", "XLS", "XLSX"];

  // no html usei assim #file_name_original
  @ViewChild("file_name_original") myInputVariable: ElementRef;
  @ViewChild(AnexoValidoMdComponent) AnexoValidoMd: AnexoValidoMdComponent;
  @ViewChild(MultiAnexosMdEComponent) modalE: MultiAnexosMdEComponent;
  @ViewChild(MultiAnexosMdDComponent) modalD: MultiAnexosMdDComponent;
  @ViewChild(MultiAnexosMdPDFComponent) modalPDF: MultiAnexosMdPDFComponent;
  @ViewChild(MultiAnexosInvalidadosMdDComponent)
  modalInvalidoD: MultiAnexosInvalidadosMdDComponent;
  @ViewChild(MultiAnexosInvalidadosMdEComponent)
  modalInvalidoE: MultiAnexosInvalidadosMdEComponent;
  @ViewChild(MultiAnexosUpdateDocMdEComponent)
  modalUpdateDocumentoE: MultiAnexosUpdateDocMdEComponent;

  constructor(
    private fb: FormBuilder,
    private _http: MultiAnexosHttpService,
    private grupoHttp: SelectAnexoGrupoHttpService,
    private subgrupoHttp: SelectAnexoSubgrupoHttpService,
    private authService: AuthService,
    private notifyMsg: NotifyMessageService,
    private spinnerService: NgxSpinnerService
  ) {
    //console.log(history.state.data);
  }

  ngOnInit() {
    this.dadosGrupo = this.grupoHttp.index().pipe(map((resp) => resp));
    this.listarPedente();
    this.listarInvalido();
    this.listarValido();
  }

  listarPedente() {
    this._http
      .filterPedente(this.authService.getEmpresa())
      .subscribe((resp) => {
        this.dadosP = resp.data;
      });
  }

  listarInvalido() {
    this._http
      .filterInvalido(this.authService.getEmpresa())
      .subscribe((resp) => {
        this.dadosI = resp.data;
      });
  }

  listarValido() {
    this._http.filterValido(this.authService.getEmpresa()).subscribe((resp) => {
      this.dadosV = resp.data;
    });
  }

  /* chamar modal*/
  ShowPessoal(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowMapaQualidade(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowEstruturaLegal(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowInstalacoesEquipamentos(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowReclamacoesApelacoes(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowDocumentacaoSistema(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowControleDocumentos(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAnaliseCriticaSGQ(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAuditoriaInterna(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAcoesCorretivas(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAcoesPreventivas(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAvaliacaoCgcre(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  showDelPedente(id_base64: any) {
    //this.registroId = atob(id_base64);
    this.registroId = id_base64;
    this.modalD.showModal();
  }

  showUpdatePedente(id_base64: any) {
    //this.registroId = atob(id_base64);
    this.registroId = id_base64;
    this.modalE.showModal();
  }

  showDelInvalido(id_base64: any) {
    this.registroId = id_base64;
    this.modalInvalidoD.showModal();
  }

  showUpdateInvalido(id_base64: any) {
    this.registroId = id_base64;
    this.modalInvalidoE.showModal();
  }

  showUpdateDocumento(id_base64: any) {
    this.registroId = id_base64;
    this.modalUpdateDocumentoE.showModal();
  }

  onSuccessUpdatePedente($event: any) {
    this.listarPedente();
    this.listarInvalido();
    this.notifyMsg.success("Registro atualizado com sucesso.");
  }

  onSuccessDeletePedente($event: any) {
    this.listarPedente();
    this.notifyMsg.success("Registro excluído com sucesso.");
  }

  onSuccessUpdateInvalido($event: any) {
    this.listarPedente();
    this.listarInvalido();
    this.notifyMsg.success("Registro excluído com sucesso.");
  }

  onSuccessDeleteInvalido($event: any) {
    this.listarInvalido();
    this.notifyMsg.success("Registro excluído com sucesso.");
  }

  onSuccessUpdateDocumento($event: any) {
    this.listarPedente();
    this.listarInvalido();
    this.notifyMsg.success("Documento atualizado com sucesso.");
  }

  onSuccessAlt($event: any) {
    // recarregar apos ecnluir conteudo via modal, (Documentos Validos)
    // window.location.reload();
  }
  showPDF(id_base64: any) {
    this.registroId = id_base64;
    this.modalPDF.showModal();
  }
}
