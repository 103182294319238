import {Component, OnInit} from '@angular/core';
import {EmpresaHttpService} from '../../../../services/http/empresa-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifyMessageService} from '../../../../services/notify-message.service';

@Component({
  selector: 'app-empresa-index',
  templateUrl: './empresa-index.component.html',
  styleUrls: ['./empresa-index.component.css']
})
export class EmpresaIndexComponent implements OnInit {

  empresas = [];

  constructor(private _http: EmpresaHttpService,
              private router: Router,
              private notifyMsg: NotifyMessageService) {
  }

  ngOnInit() {
    this.listarEmpresas();
  }

  listarEmpresas() {
    this._http.index().subscribe((resp) => {
      this.empresas = resp.data;
    });
  }

  showEdit(id: string) {
    return this.router.navigate(['/empresa/editar/', id]);
  }

  showDel(id: string) {
    this._http.destroy(atob(id)).subscribe(() => {
      this.notifyMsg.success('Registro excluído com sucesso.');
      this.listarEmpresas();
    }, error => {
      if (error.status === 500) {
        this.notifyMsg.error('Ops! Houve um problema por favor, tente novamente mais tarde.');
      }
      // this.notifyMsg.error(error.error.message);
      // console.log(error.status);
      // console.log(error.error.message);
    });
  }


}
