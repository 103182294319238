import {Component, OnInit} from '@angular/core';
import {EstruturaLegalHttpService} from '../../../../services/http/estrutura-legal-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-estrutura-legal',
  templateUrl: './estrutura-legal.component.html',
  styleUrls: ['./estrutura-legal.component.css']
})
export class EstruturaLegalComponent implements OnInit {

  dados = [];

  constructor(private _http: EstruturaLegalHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }


}
