import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AuthService} from '../../../../services/auth.service';
import {MapaAtividadeHttpService} from '../../../../services/http/mapa-atividade-http.service';
import {BsLocaleService} from 'ngx-bootstrap';

import {MapaAtividadeModalEComponent} from '../mapa-atividade-modal-e/mapa-atividade-modal-e.component';
import {MapaAtividadeModalDComponent} from '../mapa-atividade-modal-d/mapa-atividade-modal-d.component';

@Component({
  selector: 'app-mapa-atividade-c',
  templateUrl: './mapa-atividade-c.component.html',
  styleUrls: ['./mapa-atividade-c.component.css']
})
export class MapaAtividadeCComponent implements OnInit {

  dados = [];
  formData: FormGroup;

  /*apenas p/ ediação*/
  registroId: string;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(MapaAtividadeModalEComponent) modalE: MapaAtividadeModalEComponent;
  @ViewChild(MapaAtividadeModalDComponent) modalD: MapaAtividadeModalDComponent;

  constructor(private fb: FormBuilder,
              private datepicker: BsLocaleService,
              private _http: MapaAtividadeHttpService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService) {
    datepicker.use('pt-BR');
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };
 
  ngOnInit() {
    this.listarDados();
    this.formFields();
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarDados();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarDados() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.dados = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }


  private formFields() {
    this.formData = this.fb.group({
      atividade: [null, [Validators.required]],
      d_previsao: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }

}
