import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalComponent} from '../../../bootstrap/modal/modal.component';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {MultiAnexosHttpService} from '../../../../services/http/multi-anexos-http.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-multi-anexos-md-d',
    templateUrl: './multi-anexos-md-d.component.html',
    styleUrls: ['./multi-anexos-md-d.component.css']
})
export class MultiAnexosMdDComponent implements OnInit {

    _registroId: string;
    dados = <any>[];

    @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();
    @ViewChild(ModalComponent) modal: ModalComponent;

    constructor(private notifyMsg: NotifyMessageService,
                private _http: MultiAnexosHttpService,
                private spinnerService: NgxSpinnerService) {
    }

    @Input()
    set registroId(value) {
        if (value !== undefined) {
            this._registroId = value;
            this._http.show(value).subscribe((resp) => this.dados = resp.data);
        }
    }

    ngOnInit() {
    }

    showModal() {
        this.modal.show();
    }

    destroy() {
        this.spinnerService.show();
        this.modal.hide();
        this._http.destroy(this._registroId).subscribe(() => {
            this.spinnerService.hide();
            this.onSuccess.emit();
        }, error => this.notifyMsg.error(error.error.message));
    }
}
