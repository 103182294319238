import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MultiAnexosHttpService} from '../../../../services/http/multi-anexos-http.service';
import {AuthService} from '../../../../services/auth.service';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-multi-anexos-update-doc-md-e',
    templateUrl: './multi-anexos-update-doc-md-e.component.html',
    styleUrls: ['./multi-anexos-update-doc-md-e.component.css']
})
export class MultiAnexosUpdateDocMdEComponent implements OnInit {

    formMultData: FormGroup;
    _registroId: string;
    dados = <any>[];
    dadosPessoal: Observable<Array<any>>; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
    pessoaId: string;

    selectedFile: string;

    @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();
    @ViewChild(ModalLgComponent) modal: ModalLgComponent;
    @ViewChild('file_name_original') myInputVariable: ElementRef;  // no html usei assim #file_name_original

    constructor(private fb: FormBuilder,
                private _http: MultiAnexosHttpService,
                private authService: AuthService,
                private notifyMsg: NotifyMessageService,
                private spinnerService: NgxSpinnerService) {
    }

    @Input()
    set registroId(value) {
        if (value !== undefined) {
            this._registroId = value;
            this._http.show(value).subscribe((resp) => {
                    this.formMultData.patchValue(resp.data);
                }
            );
        }
    }

    ngOnInit() {
        this.formFields();
    }

    // onSelectedFile(event) {
    //   if (event.target.files.length > 0) {
    //     const file = event.target.files[0];
    //     this.img = this.formMultData.get('file_name_original').setValue(file);
    //   }
    // }

    onSelectedFile(e) {
        this.selectedFile = e && e.target.files.length > 0 ? e.target.files[0] : '';
    }

    onSubmitUpdate() {
        const formData: FormData = new FormData();
        formData.append('file_name_original', this.selectedFile);
        this._http.store(formData).subscribe(() => {
        });
    }

    showModal() {
        this.modal.show();
    }

    private formFields() {
        this.formMultData = this.fb.group({
            file_name_original: [null, [Validators.required]],
            grupo_id: [null, [Validators.required]],
            subgrupo_id: [null, [Validators.required]],
            users_id: [this.authService.profile.id, [Validators.required]],
            empresa_id: [this.authService.getEmpresa(), [Validators.required]],
        });
    }
}
