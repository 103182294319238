import {Component, OnInit, ViewChild, HostListener} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../services/notify-message.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SessaoHttpService} from '../../../services/http/sessao-http.service';
import {AjudaMdComponent} from "../painel/ajuda-md/ajuda-md.component";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    @ViewChild(AjudaMdComponent) boxAjuda: AjudaMdComponent;
    formData: FormGroup;
    showModalLogin: boolean;

    @HostListener('document:keydown', ['$event'])
        handleKeyboardEvent(event: KeyboardEvent) { 
            if (event && this.showModalLogin && event.key == 'Escape') {
                this.hideFormLogin();
            }
    }

    constructor(private authService: AuthService,
                private _httpSessao: SessaoHttpService,
                private router: Router,
                private fb: FormBuilder,
                private notifyMsg: NotifyMessageService,
                private spinnerService: NgxSpinnerService) {
                
                    this.showModalLogin = false;
    }

    ngOnInit() {
        this.formFields();
        // console.log(window.location);
        // console.log(window.location.host);
        // console.log(window.location.hostname);
        // console.log(window.location.port);
        // console.log(window.location.protocol);
        
    }


    onSubmit() {
        this.spinnerService.show();
        if (this.formData.valid) {
            this.authService.login(this.formData.value)
                .subscribe(() => {
                    this.formData.reset();
                    this.notifyMsg.success(`Olá, ${this.authService.profile.name}`);
                    this.spinnerService.hide();

                    // Escolha de empresa para todos
                    this.authService.setEmpresa(null);
                    this.router.navigate(['/loading']); // abre a modal p/ escolha da empresa

                }, (error) => {
                    if (error.status === 400) {
                        this.formData.reset();
                        this.notifyMsg.notice('Login ou a senha está incorreta!');
                        this.spinnerService.hide();
                    } else {
                        this.notifyMsg.error(error.error.message);
                    }
                });
        } else {
            this.notifyMsg.info('Por favor preencha todos os campos');
            this.spinnerService.hide();
        }
        return false;
    }

    private formFields() {
        this.formData = this.fb.group({
            login: [null, [Validators.required, Validators.maxLength(30)]],
            password: [null, [Validators.required, Validators.maxLength(15)]]
            // login: ['iqcontroller', [Validators.required, Validators.maxLength(30)]],
            //password: ['secret', [Validators.required, Validators.maxLength(15)]]
        });
    }

    showFormAjuda() {
        this.boxAjuda.showModal();
    }

    showFormLogin() {
        //console.log('Show Login')
        this.showModalLogin = true;
    }

    hideFormLogin() {
        //console.log('Hide Login')
        this.showModalLogin = false;
    }

}
