import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AquisicaoRecebimentoHttpService} from '../../../../services/http/aquisicao-recebimento-http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';
import {FornecedorHttpService} from '../../../../services/http/fornecedor-http.service';

declare var $: any;

@Component({
  selector: 'app-aquisicao-recebimento-modal-e',
  templateUrl: './aquisicao-recebimento-modal-e.component.html',
  styleUrls: ['./aquisicao-recebimento-modal-e.component.css']
})
export class AquisicaoRecebimentoModalEComponent implements OnInit {

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  formData: FormGroup;
  dadosPessoal: Observable<Array<any>>; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
  dadosSolicitante: Observable<Array<any>>; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
  dadosFornecedor: Observable<Array<any>>; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
  _registroId: string;
  pessoaId: string;
  solicitanteId: string;
  fornecedorId: string;

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this._http.show(value).subscribe((resp) => {
          this.formData.patchValue(resp.data);
        }
      );
    }
  }

  constructor(private fb: FormBuilder,
              private _http: AquisicaoRecebimentoHttpService,
              private _httpPessoal: PessoalHttpService,
              private _httpSolicitante: PessoalHttpService,
              private _httpFornecedor: FornecedorHttpService,
              private notifyMsg: NotifyMessageService) {
  }

  ngOnInit() {
    $(function () {
      $('#data_a,#data_r').mask('00/00/0000');
    });

    this.formFields();
    this.dadosPessoal = this._httpPessoal.index().pipe(map(resp => resp.data));
    this.dadosSolicitante = this._httpSolicitante.index().pipe(map(resp => resp.data));
    this.dadosFornecedor = this._httpFornecedor.index().pipe(map(resp => resp.data));
  }

  onSubmitUpdate() {
    this._http.update(this._registroId, this.formData.value)
      .subscribe(() => {
        this.modal.hide();
        this.onSuccess.emit();
      }, error => this.notifyMsg.error(error.error.message));
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    this.formData = this.fb.group({
      data_a: [null, [Validators.required]],
      solicitante: [null, [Validators.required]],
      fornecedor: [null, [Validators.required]],
      produto_servico: [null, [Validators.required]],
      data_r: [null],
      responsavel: [null, [Validators.required]],
      parecer: [null, [Validators.required]]
    });
  }

  /*comobox responsavel*/
  getPessoaId(id) {
    this.pessoaId = id.value;
  }

  /*comobox solicitante*/
  getSolicitanteId(id) {
    this.solicitanteId = id.value;
  }

  /*comobox fornecedor*/
  getForncedorId(id) {
    this.fornecedorId = id.value;
  }

}
