import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsLocaleService} from 'ngx-bootstrap';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {RiscoImparcialidadeHttpService} from '../../../../services/http/risco-imparcialidade-http.service';
import {AuthService} from '../../../../services/auth.service';
import {RegRiscoImparcialidadeModalEComponent} from '../reg-risco-imparcialidade-modal-e/reg-risco-imparcialidade-modal-e.component';
import {RegRiscoImparcialidadeModalDComponent} from '../reg-risco-imparcialidade-modal-d/reg-risco-imparcialidade-modal-d.component';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';

declare var $: any;

@Component({
  selector: 'app-reg-risco-imparcialidade-c',
  templateUrl: './reg-risco-imparcialidade-c.component.html',
  styleUrls: ['./reg-risco-imparcialidade-c.component.css']
})
export class RegRiscoImparcialidadeCComponent implements OnInit {

  formRegistroRisco: FormGroup;
  registros = [];
  dadosPessoal: Observable<Array<any>>; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
  registroId: string;
  pessoaId: string;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(RegRiscoImparcialidadeModalEComponent) modalE: RegRiscoImparcialidadeModalEComponent;
  @ViewChild(RegRiscoImparcialidadeModalDComponent) modalD: RegRiscoImparcialidadeModalDComponent;

  constructor(private fb: FormBuilder,
              private datepicker: BsLocaleService,
              private notifyMsg: NotifyMessageService,
              private _http: RiscoImparcialidadeHttpService,
              private _httpPessoal: PessoalHttpService,
              private authService: AuthService) {
    datepicker.use('pt-BR');
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  ngOnInit() {
    $(function () {
      $('#data').mask('00/00/0000');
    });

    this.formFields();
    this.listarRiscos();
    this.dadosPessoal = this._httpPessoal.index().pipe(map(resp => resp.data));
  }

  onSubmitInsert() {
    if (this.formRegistroRisco.valid) {
      this._http.store(this.formRegistroRisco.value)
        .subscribe(() => {
          this.formRegistroRisco.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarRiscos();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarRiscos() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.registros = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarRiscos();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  private formFields() {
    this.formRegistroRisco = this.fb.group({
      data: [null, [Validators.required]],
      ricos: [null],
      responsavel: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarRiscos();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarRiscos();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }

  /*comobox resposanvel*/
  getPessoaId(id) {
    this.pessoaId = id.value;
  }
}
