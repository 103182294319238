import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AnexosPessoalCnhRgHttpService} from '../../../../services/http/anexos-pessoal-cnh-rg-http.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {FileUploadService} from '../../../../file-upload.service';
import {UploadDocPessoaHttpService} from '../../../../services/http/upload-doc-pessoa-http.service';

@Component({
  selector: 'app-anexo-doc-cnh-ps-md',
  templateUrl: './anexo-doc-cnh-ps-md.component.html',
  styleUrls: ['./anexo-doc-cnh-ps-md.component.css']
})
export class AnexoDocCnhPsMdComponent implements OnInit {

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  profileForm: FormGroup;
  _registroId: string;

  fileUpload = {status: '', message: '', filePath: ''};
  error: string;

  constructor(private fb: FormBuilder,
              private fileUploadService: UploadDocPessoaHttpService,
              private _httpAnexo: AnexosPessoalCnhRgHttpService,
              private notifyMsg: NotifyMessageService,
              private spinnerService: NgxSpinnerService) {
  }

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
    }
  }

  ngOnInit() {
    this.formFields();
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    this.profileForm = this.fb.group({
      data_emissao: [null],
      data_validade: [null],
      nome_documento: [null],
      file_name: [null, [Validators.required]],
      //users_id: [this._registroId, [Validators.required]] // nao necessario já estou pegando lá em baixo
    });
  }

  // uploadAnexos(files: FileList) {
  //   if (!files.length) {
  //     return;
  //   }
  //   this.spinnerService.show();
  //   this.modal.hide();
  //   this._httpAnexo.create(this._registroId, files).subscribe((resp) => {
  //     this.spinnerService.hide();
  //     this.notifyMsg.success('Documento enviado com sucesso!');
  //     this.onSuccess.emit(resp);
  //   }, error => {
  //     this.spinnerService.hide();
  //     this.onError.emit(error);
  //     this.notifyMsg.error(error.error.message);
  //   });
  // }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get('file_name').setValue(file);
    }
  }

  onSubmitInsert() {
    const formData = new FormData();
    formData.append('data_emissao', this.profileForm.get('data_emissao').value);
    formData.append('data_validade', this.profileForm.get('data_validade').value);
    formData.append('nome_documento', this.profileForm.get('nome_documento').value);
    formData.append('file_name', this.profileForm.get('file_name').value);
    formData.append('users_id', this._registroId);

    // this.fileUploadService.uploadDoc(formData).subscribe(
    //   res => this.fileUpload = res,
    //   err => this.error = err
    // );

    this.spinnerService.show();
    this.modal.hide();
    this.fileUploadService.uploadDoc(formData).subscribe((resp) => {
      this.spinnerService.hide();
      this.notifyMsg.success('Documento enviado com sucesso!');
      this.onSuccess.emit(resp);
    }, error => {
      this.spinnerService.hide();
      this.onError.emit(error);
      this.notifyMsg.error(error.error.message);
    });
  }


}
