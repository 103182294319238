import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {GruposHttpService} from '../../../../services/http/grupos-http.service';
import {SubGruposHttpService} from '../../../../services/http/sub-grupos-http.service';

declare var $: any;

@Component({
  selector: 'app-sub-grupos-modal-e',
  templateUrl: './sub-grupos-modal-e.component.html',
  styleUrls: ['./sub-grupos-modal-e.component.css']
})
export class SubGruposModalEComponent implements OnInit {

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  formData: FormGroup;
  _registroId: string;
  grupos: Array<any>;

  @Input()
  set registroId(value) {
    //console.log('value: ' + value)
    if (value !== undefined) {
      this._registroId = value;
      this._http.getById(value).subscribe((resp) => {
          //console.log(resp);
          this.formData.patchValue(resp[0]);
        }
      );
    }
  }

  constructor(private fb: FormBuilder,
              private _http: SubGruposHttpService,
              private _httpGrupos: GruposHttpService,
              private notifyMsg: NotifyMessageService) {
  }

  ngOnInit() {
    this.formFields();

    this._httpGrupos.index().subscribe((resp) => {
      this.grupos = resp;
    });
    
  }

  onSubmitUpdate() {
    this._http.update(this._registroId, this.formData.value)
      .subscribe(() => {
        this.modal.hide();
        this.onSuccess.emit();
      }, error => this.notifyMsg.error(error.error.message));
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    this.formData = this.fb.group({
      grupo_id: [null, [Validators.required]],
      descricao: [null, [Validators.required]],
      instrucoes: [null],
      dt_prox_acao_obrigatorio: [null, [Validators.required]],
    });
  }

}
