import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { AjudaMdComponent } from "../../pages/painel/ajuda-md/ajuda-md.component";
import { EscolherEmpresaModalComponent } from "../../pages/escolher-empresa-modal/escolher-empresa-modal.component";
import { environment } from "src/environments/environment";

declare var $: any;

@Component({
  selector: "app-navbar-principal",
  templateUrl: "./navbar-principal.component.html",
  styleUrls: ["./navbar-principal.component.css"],
})
export class NavbarPrincipalComponent implements OnInit {
  @ViewChild(AjudaMdComponent) boxAjuda: AjudaMdComponent;

  @ViewChild(EscolherEmpresaModalComponent)
  modalOrganismo: EscolherEmpresaModalComponent;

  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit() {
    $("#cssmenu").menumaker({
      title: "",
      format: "multitoggle",
    });
  }

  logout() {
    this.authService.logout().subscribe(() => this.router.navigate(["login"]));
  }

  goBackstage() {
    if (!this.authService.libMasterMentorConsultor()) {
      return false;
    }
    const token = this.authService.getToken();
    window.location.href = `${environment.backstage.url}?auth=${token}`;
  }

  changeCompany() {
    this.modalOrganismo.showModal();
  }

  onSuccessAtivo($event: any) {
    location.href = "/dashboard";
  }

  // liberado() {
  //   //https://www.tutorialspoint.com/typescript/typescript_if_else_statement.htm
  //   //https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Operadores_Logicos
  //
  //   if (this.authService.profile.nivel === '1' || this.authService.profile.nivel === '2') {
  //     this.masterMenor = true;
  //   }
  // }

  showFormAjuda() {
    this.boxAjuda.showModal();
  }
}
