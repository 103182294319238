import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {AuthService} from '../../../../services/auth.service';
import {MsgGeralHttpService} from '../../../../services/http/msg-geral-http.service';
import {MovimentoMsgHttpService} from '../../../../services/http/movimento-msg-http.service';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {ModalOffCloseComponent} from '../../../bootstrap/modal-off-close/modal-off-close.component';

@Component({
    selector: 'app-box-alerta-empresa-md',
    templateUrl: './box-alerta-empresa-md.component.html',
    styleUrls: ['./box-alerta-empresa-md.component.css']
})
export class BoxAlertaEmpresaMdComponent implements OnInit {

    msg = [];

    @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

    //@ViewChild(ModalLgComponent) modal: ModalLgComponent;
    @ViewChild(ModalOffCloseComponent) modal: ModalOffCloseComponent;

    constructor(private _http: MovimentoMsgHttpService,
                private httpMsg: MsgGeralHttpService,
                private authService: AuthService,
                private notifyMsg: NotifyMessageService) {

    }

    ngOnInit() {
        this.httpMsg.listarUltimaEnviada().subscribe((resp) => {
            this.msg = resp.data;
            this.verificar();
        });
    }


    showModal() {
        this.modal.show();
    }


    verificar() {

        if (this.msg[0]) {
            this._http.verifcarMovimento(this.authService.getEmpresa(), this.msg[0].id).subscribe((resp) => {
                if (resp.data.length === 0) {
                    this.onSuccess.emit();
                }
            });
        }

    }


    estouCiente() {
        this._http.store({
            empresa_id: this.authService.getEmpresa(),
            msg_geral_id: this.msg[0].id,
            users_id: this.authService.profile.id
        }).subscribe(() => {
            this.notifyMsg.success('Registro incluido com sucesso.');
            this.modal.hide();
        });
    }

}
