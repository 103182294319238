import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { SelectAnexoGrupoHttpService } from "../../../../services/http/select-anexo-grupo-http.service";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import { SelectAnexoSubgrupoHttpService } from "../../../../services/http/select-anexo-subgrupo-http.service";
import { map } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../../services/auth.service";
import { MultiAnexosHttpService } from "../../../../services/http/multi-anexos-http.service";
import { AnexoValidoMdComponent } from "../anexo-valido-md/anexo-valido-md.component";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from "moment";

@Component({
  selector: "app-multi-anexos",
  templateUrl: "./multi-anexos.component.html",
  styleUrls: ["./multi-anexos.component.css"],
})
export class MultiAnexosComponent implements OnInit {
  formMultData: FormGroup;
  anexoId?: string;
  formFill?: Record<string,any>;
  dadosGrupo: Observable<Array<any>>;
  dadosSubgrupo: Observable<Array<any>>;
  dadosP = [];
  dadosI = [];
  dadosV = [];
  //dadosVPorGrupo = [];

  getGrupoId: number;
  getTitle: any;
  registroId: string;
  subGrupo: any;
  subGrupoInstrucoes: string;
  subGrupoDtProxAcaoObrigatoria: boolean;
  observacao: string;
  observacao_organismo: string;

  // no html usei assim #file_name_original
  @ViewChild("file_name_original") myInputVariable: ElementRef;
  @ViewChild(AnexoValidoMdComponent) AnexoValidoMd: AnexoValidoMdComponent;

  constructor(
    private fb: FormBuilder,
    private _http: MultiAnexosHttpService,
    private grupoHttp: SelectAnexoGrupoHttpService,
    private subgrupoHttp: SelectAnexoSubgrupoHttpService,
    private authService: AuthService,
    private notifyMsg: NotifyMessageService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    //console.log(history.state.data);
  }


  ngOnInit() {

    this.dadosGrupo = this.grupoHttp.index().pipe(map((resp) => resp));
    this.anexoId = this.route.snapshot.paramMap.get('id') || undefined;
    this.formFields();
    this.loadFormData();
  }
  spinner() {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }
  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formMultData.get("file_name_original").setValue(file);
    }
  }

  onSubmitInsert() {
    if (this.formMultData.valid) {
      this.spinnerService.show();
      const formData = new FormData();

      formData.append(
        "file_name_original",
        this.formMultData.get("file_name_original").value
      );
      formData.append("grupo_id", this.formMultData.get("grupo_id").value);
      formData.append(
        "subgrupo_id",
        this.formMultData.get("subgrupo_id").value
      );
      formData.append("data_inclusao", moment().format("YYYY-MM-DD"));
      formData.append(
        "data_elaboracao",
        moment(
          this.formMultData.get("data_elaboracao").value,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD")
      );
      formData.append(
        "data_proxima_acao",
        this.formMultData.get("data_proxima_acao").value
          ? moment(
              this.formMultData.get("data_proxima_acao").value,
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD")
          : "false"
      );
      formData.append(
        "periodo_lembrete",
        this.formMultData.get("periodo_lembrete").value
      );
      formData.append("users_id", this.formMultData.get("users_id").value);
      formData.append("empresa_id", this.formMultData.get("empresa_id").value);
      formData.append("observacao", this.formMultData.get("observacao").value);
      formData.append(
        "observacao_organismo",
        this.formMultData.get("observacao_organismo").value
      );
      formData.append("updateDoc", "nao");

      // this._http.uploadMultiDoc(formData).subscribe(() => {
      if (this.anexoId != null) {

        this._http.store(formData,this.anexoId).subscribe(
          () => {
            this.notifyMsg.success("Registro atualizado com sucesso.");
            this.myInputVariable.nativeElement.value = null; // removendo o input type:file

            this.formMultData.reset({
              grupo_id: { value: "", disabled: false },
              subgrupo_id: { value: "", disabled: true },
              data_elaboracao: { value: "", disabled: true },
              data_proxima_acao: { value: "", disabled: true },
              periodo_lembrete: { value: "", disabled: true },
              observacao: { value: "", disabled: false },
              observacao_organismo: { value: "", disabled: false },
              file_name_original: { value: "", disabled: false },
              users_id: { value: this.authService.profile.id, disabled: false },
              //resposanvel_id: {value: this.authService.profile.id, disabled: false},
              empresa_id: {
                value: this.authService.getEmpresa(),
                disabled: false,
              },
            });
            this.subGrupoInstrucoes = undefined;
            this.subGrupoDtProxAcaoObrigatoria = undefined;

            this.spinnerService.hide();
          } ,
        (error) => {
          this.notifyMsg.error(error.error.message);
          this.spinnerService.hide();
        }) }
      else {
        this._http.store(formData).subscribe(
            () => {
              this.notifyMsg.success("Registro incluido com sucesso.");
              this.myInputVariable.nativeElement.value = null; // removendo o input type:file

              this.formMultData.reset({
                grupo_id: { value: "", disabled: false },
                subgrupo_id: { value: "", disabled: true },
                data_elaboracao: { value: "", disabled: true },
                data_proxima_acao: { value: "", disabled: true },
                periodo_lembrete: { value: "", disabled: true },
                observacao: { value: "", disabled: false },
                observacao_organismo: { value: "", disabled: false },
                file_name_original: { value: "", disabled: false },
                users_id: { value: this.authService.profile.id, disabled: false },
                //resposanvel_id: {value: this.authService.profile.id, disabled: false},
                empresa_id: {
                  value: this.authService.getEmpresa(),
                  disabled: false,
                },
              });
              this.subGrupoInstrucoes = undefined;
              this.subGrupoDtProxAcaoObrigatoria = undefined;

              this.spinnerService.hide();
            },
            (error) => {
              this.notifyMsg.error(error.error.message);
              this.spinnerService.hide();

      }
      )
      }
    } else {
      this.spinnerService.hide();
      this.notifyMsg.info("Por favor preencha os campos obrigatórios.");
    }
  }

  setGrupo(value: any) {
    this.dadosSubgrupo = this.subgrupoHttp
      .getGrupo(value)
      .pipe(map((resp) => resp));

    this.formMultData.reset({
      grupo_id: { value: value, disabled: false },
      subgrupo_id: { value: "", disabled: false },
      data_elaboracao: { value: "", disabled: true },
      data_proxima_acao: { value: "", disabled: true },
      periodo_lembrete: { value: "", disabled: true },
      observacao: { value: "", disabled: false },
      observacao_organismo: { value: "", disabled: false },
      file_name_original: {
        value: this.formMultData.get("file_name_original").value,
        disabled: false,
      },
      users_id: { value: this.authService.profile.id, disabled: false },
      //resposanvel_id: {value: this.authService.profile.id, disabled: false},
      empresa_id: { value: this.authService.getEmpresa(), disabled: false },
    });

    this.subGrupoInstrucoes = undefined;
    this.subGrupoDtProxAcaoObrigatoria = undefined;
  }

  async setSubgrupo(value) {
    if (value === undefined) {
      this.notifyMsg.notice("Por favor, selecione a pasta.");
    } else {
      let subGrupoID = this.formMultData.get("subgrupo_id").value;

      await this.dadosSubgrupo.subscribe((sub) => {
        this.subGrupo = sub.filter((s) => s.id == subGrupoID);
        this.subGrupoInstrucoes = this.subGrupo.map((s) => s.instrucoes);
        this.subGrupoDtProxAcaoObrigatoria =
          this.subGrupo.map((s) => s.dt_prox_acao_obrigatorio) == 1;

        if (this.subGrupoDtProxAcaoObrigatoria) {
          this.formMultData.controls["data_proxima_acao"].clearValidators();
          this.formMultData.controls["data_proxima_acao"].setValidators([
            Validators.required,
          ]);
          this.formMultData.controls[
            "data_proxima_acao"
          ].updateValueAndValidity();

          this.formMultData.controls["periodo_lembrete"].clearValidators();
          this.formMultData.controls["periodo_lembrete"].setValidators([
            Validators.required,
          ]);
          this.formMultData.controls[
            "periodo_lembrete"
          ].updateValueAndValidity();
        } else {
          this.formMultData.controls["data_proxima_acao"].clearValidators();
          this.formMultData.controls[
            "data_proxima_acao"
          ].updateValueAndValidity();

          this.formMultData.controls["periodo_lembrete"].clearValidators();
          this.formMultData.controls[
            "periodo_lembrete"
          ].updateValueAndValidity();
        }

        this.formMultData.controls["data_elaboracao"].reset();
        this.formMultData.controls["data_proxima_acao"].reset();
        this.formMultData.controls["periodo_lembrete"].reset();
        this.formMultData.controls["data_elaboracao"].enable();
        this.formMultData.controls["data_proxima_acao"].enable();
        this.formMultData.controls["periodo_lembrete"].enable();

        this.formMultData.controls["data_elaboracao"].setValue(
          moment().format("DD/MM/YYYY")
        );

        this.formMultData.controls["periodo_lembrete"].setValue(0);

        if (this.subGrupoDtProxAcaoObrigatoria) {
          this.formMultData.controls["data_proxima_acao"].setValue(
            moment().add(1, "M").format("DD/MM/YYYY")
          );
        }
      });
    }
  }

  private  formFields() {
    this.formMultData = this.fb.group({
      file_name_original: [null, [Validators.required]],
      grupo_id: [null, [Validators.required]],
      subgrupo_id: [null, [Validators.required]],
      data_elaboracao: [null, [Validators.required]],
      data_proxima_acao: [null, [Validators.required]],
      periodo_lembrete: [null, [Validators.required]],
      observacao: [null, []],
      observacao_organismo: [null, []],
      empresa_id: [this.authService.getEmpresa(), [Validators.required]],
      users_id: [this.authService.profile.id, [Validators.required]],
    });
  }

  private async loadFormData() {
    if(this.anexoId != null ) {
      this.spinner();
      let formFill: any ;

      await this._http.show(this.anexoId).toPromise().then((res) => formFill = res.data );

      this.dadosSubgrupo = await this.subgrupoHttp
          .getGrupo(formFill.grupo_id)
          .pipe(map((resp) => resp));

      this.formMultData.controls["subgrupo_id"].enable();
      this.formMultData.controls["data_elaboracao"].enable();
      this.formMultData.controls["data_proxima_acao"].enable();
      this.formMultData.controls["periodo_lembrete"].enable();

      this.formMultData.setValue({
        file_name_original:formFill.file_name_original,
        grupo_id:formFill.grupo_id,
        subgrupo_id:formFill.subgrupo_id,
        data_elaboracao: formFill.data_elaboracao? new Date():null,
        data_proxima_acao: null,
        periodo_lembrete: null,
        observacao:formFill.observacao || '',
        observacao_organismo:formFill.observacao_organismo || '',
        empresa_id:this.authService.getEmpresa(),
        users_id:this.authService.profile.id
      });


    }
  }

  /* chamar modal*/
  ShowPessoal(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowMapaQualidade(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowEstruturaLegal(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowInstalacoesEquipamentos(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowReclamacoesApelacoes(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowDocumentacaoSistema(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowControleDocumentos(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAnaliseCriticaSGQ(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAuditoriaInterna(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAcoesCorretivas(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAcoesPreventivas(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  ShowAvaliacaoCgcre(number: number, title: string) {
    this.getGrupoId = number;
    this.getTitle = title;
    this.AnexoValidoMd.showModal();
  }

  onSuccessAlt($event: any) {
    // recarregar apos ecnluir conteudo via modal, (Documentos Validos)
    // window.location.reload();
  }
}
