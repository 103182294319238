import {Component, OnInit} from '@angular/core';
import {RelacaoEquipamentoHttpService} from '../../../../services/http/relacao-equipamento-http.service';
import {VariadosService} from '../../../../services/variados.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-relacao-equipamento',
  templateUrl: './relacao-equipamento.component.html',
  styleUrls: ['./relacao-equipamento.component.css']
})
export class RelacaoEquipamentoComponent implements OnInit {
  dados = [];

  constructor(private _http: RelacaoEquipamentoHttpService,
              public variados: VariadosService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
