import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MultiAnexosHttpService } from "../../../../services/http/multi-anexos-http.service";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import { BsLocaleService } from "ngx-bootstrap";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { ModalLgComponent } from "../../../bootstrap/modal-lg/modal-lg.component";
import { PessoalHttpService } from "../../../../services/http/pessoal-http.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthService } from "../../../../services/auth.service";
import { SelectAnexoGrupoHttpService } from "../../../../services/http/select-anexo-grupo-http.service";
import { SelectAnexoSubgrupoHttpService } from "../../../../services/http/select-anexo-subgrupo-http.service";
import * as moment from "moment";

@Component({
  selector: "app-multi-anexos-md-e",
  templateUrl: "./multi-anexos-md-e.component.html",
  styleUrls: ["./multi-anexos-md-e.component.css"],
})
export class MultiAnexosMdEComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;
  formData: FormGroup;
  _registroId: string;
  dados = <any>[];
  dadosPessoal: Observable<
    Array<any>
  >; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
  pessoaId: string;
  nomeColaborador: string;
  nomeValidador: string;
  dataInclusao: string;
  dataValidacao: string;
  pendente: boolean = false;
  grupo_id: number = 2;
  subgrupo_id: number = null;

  dadosGrupo: Observable<Array<any>>; // Grupo de Pastas
  dadosSubgrupo: Observable<Array<any>>; // Grupo de subpastas
  subGrupo: any;
  getGrupoId: number;
  subGrupoInstrucoes: string;
  subGrupoDtProxAcaoObrigatoria: boolean;

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();
  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  constructor(
    private fb: FormBuilder,
    private _http: MultiAnexosHttpService,
    private _httpPessoal: PessoalHttpService,
    private notifyMsg: NotifyMessageService,
    private authService: AuthService,
    private datepicker: BsLocaleService,
    private grupoHttp: SelectAnexoGrupoHttpService,
    private subgrupoHttp: SelectAnexoSubgrupoHttpService
  ) {
    datepicker.use("pt-BR");
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: "DD/MM/YYYY",
        adaptivePosition: true,
      }
    );
  }

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this._http.show(value).subscribe((resp) => {
        // Se o status estiver em "pendente", altera para "válido"
        // Se a data for null, formata com a data do dia
        if (parseInt(resp.data.status) == 1) {
          resp.data.status = "3";
          resp.data.data_validade = moment().toDate();
          this.pendente = true;
        } else {
          resp.data.data_validade = moment(resp.data.data_validade).toDate();
        }

        // Formata a observação para uma string vazia caso o valor venha como null ou undefined
        if (resp.data.observacao == null) {
          resp.data.observacao = "";
        }

        if (resp.data.observacao_organismo == null) {
          resp.data.observacao_organismo = "";
        }

        this.formData.patchValue(resp.data);
        this.formData.patchValue({
          data_elaboracao: moment(resp.data.data_elaboracao).toDate(),
          data_inclusao: moment(resp.data.data_inclusao).toDate(),
          data_proxima_acao: resp.data.data_proxima_acao
            ? moment(resp.data.data_proxima_acao).toDate()
            : null,
        });
        this.nomeColaborador = resp.data.colaborador;
        this.nomeValidador = resp.data.validador;
        this.grupo_id = resp.data.grupo_id;
        this.subgrupo_id = resp.data.subgrupo_id;
        this.setGrupo(this.grupo_id);
        this.dataInclusao = moment(resp.data.data_inclusao).format(
          "DD/MM/YYYY"
        );
        this.dataValidacao = moment(resp.data.data_validade).format(
          "DD/MM/YYYY"
        );
      });
    }
  }

  ngOnInit() {
    this.dadosGrupo = this.grupoHttp.index().pipe(map((resp) => resp));

    this.formFields();
    this.dadosPessoal = this._httpPessoal
      .index()
      .pipe(map((resp) => resp.data));
  }

  onSubmitUpdate() {
    this.formData.patchValue({
      resposanvel_id: this.authService.profile.id,
    });
    if (this.formData.valid) {
      let request = { ...this.formData.value };

      request.data_validade = moment(this.formData.value.data_validade).format(
        "YYYY-MM-DD"
      );
      request.data_elaboracao = moment(
        this.formData.value.data_elaboracao
      ).format("YYYY-MM-DD");
      request.data_inclusao = moment(this.formData.value.data_inclusao).format(
        "YYYY-MM-DD"
      );
      request.data_proxima_acao = this.formData.value.data_proxima_acao
        ? moment(this.formData.value.data_proxima_acao).format("YYYY-MM-DD")
        : null;

      this.dataInclusao = moment(this.formData.value.data_inclusao).format(
        "DD/MM/YYYY"
      );
      this.dataValidacao = moment(this.formData.value.data_validade).format(
        "DD/MM/YYYY"
      );

      this._http.update(this._registroId, request).subscribe(
        () => {
          this.modal.hide();
          this.onSuccess.emit();
        },
        (error) => this.notifyMsg.error(error.message)
      );
    } else {
      this.notifyMsg.info("Por favor preencha os campos obrigatórios.");
    }
  }

  showModal() {
    this.modal.show();
  }

  setGrupo(value: any) {
    this.dadosSubgrupo = this.subgrupoHttp
      .getGrupo(value)
      .pipe(map((resp) => resp));
  }

  async setSubgrupo(value) {
    if (value === undefined) {
      this.notifyMsg.notice("Por favor, selecione a pasta.");
    } else {
      let subGrupoID = this.formData.get("subgrupo_id").value;

      await this.dadosSubgrupo.subscribe((sub) => {
        // Recebe a lista de subgrupos

        this.subGrupo = sub.filter((s) => s.id == subGrupoID);
        this.subGrupoInstrucoes = this.subGrupo.map((s) => s.instrucoes);
        this.subGrupoDtProxAcaoObrigatoria =
          this.subGrupo.map((s) => s.dt_prox_acao_obrigatorio) == 1;

        // Altera os campos de validação

        if (this.subGrupoDtProxAcaoObrigatoria) {
          this.formData.controls["data_proxima_acao"].clearValidators();
          this.formData.controls["data_proxima_acao"].setValidators([
            Validators.required,
          ]);
          this.formData.controls["data_proxima_acao"].updateValueAndValidity();

          this.formData.controls["periodo_lembrete"].clearValidators();
          this.formData.controls["periodo_lembrete"].setValidators([
            Validators.required,
          ]);
          this.formData.controls["periodo_lembrete"].updateValueAndValidity();
        } else {
          this.formData.controls["data_proxima_acao"].clearValidators();
          this.formData.controls["data_proxima_acao"].updateValueAndValidity();

          this.formData.controls["periodo_lembrete"].clearValidators();
          this.formData.controls["periodo_lembrete"].updateValueAndValidity();
        }
      });
    }
  }

  private formFields() {
    this.formData = this.fb.group({
      file_name_original: [null, [Validators.required]],
      status: [null, [Validators.required]],
      data_validade: [null, [Validators.required]],
      data_inclusao: [null, [Validators.required]],
      data_elaboracao: [null, [Validators.required]],
      data_proxima_acao: [null, []],
      periodo_lembrete: [null, [Validators.required]],
      grupo_id: [null, [Validators.required]],
      subgrupo_id: [null, [Validators.required]],
      observacao: ["", []],
      observacao_organismo: ["", []],
      resposanvel_id: [this.authService.profile.id, [Validators.required]],
    });
  }

  /*comobox responsavel*/
  getPessoaId(id) {
    this.pessoaId = id.value;
  }
}
