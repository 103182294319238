import {Component, OnInit} from '@angular/core';
import {FornecedorHttpService} from '../../../../services/http/fornecedor-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-fornecedor',
  templateUrl: './fornecedor.component.html',
  styleUrls: ['./fornecedor.component.css']
})
export class FornecedorComponent implements OnInit {

  dados = [];

  constructor(private _http: FornecedorHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
