import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ng2-anexo-pessoal',
  templateUrl: './ng2-anexo-pessoal.component.html',
  styleUrls: ['./ng2-anexo-pessoal.component.css']
})
export class Ng2AnexoPessoalComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
