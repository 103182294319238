import {Injectable} from '@angular/core';
import PNotify from 'pnotify/dist/es/PNotify';
import PNotifyButtons from 'pnotify/dist/es/PNotifyButtons';

@Injectable({
  providedIn: 'root'
})
export class NotifyMessageService {

  constructor() {
  }

  success(text: string) {
    this.alert(text, Types.success, 1500);
  }

  error(text: string) {
    this.alert(text, Types.error, 4000);
  }

  info(text: string) {
    this.alert(text, Types.info, 3000);
  }

  notice(text: string) {
    this.alert(text, Types.notice, 3000);
  }

  private alert(text: string, type: Types, time: number) {
    this.pnotify.alert({text, type, delay: time});

    // this.pnotify.alert({
    //   title: 'The notice title.',
    //   text,
    //   type,
    //   delay: 2000,
    //   icon: 'far fa-address-card',
    //   hide: true
    // });
  }

  private get pnotify() {
    PNotifyButtons;
    return PNotify;
  }

}

enum Types {
  success = 'success',
  error = 'error',
  info = 'info',
  notice = 'notice'
}
