import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AnexosPessoalHabilitacaoHttpService} from '../../../../services/http/anexos-pessoal-habilitacao-http.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-anexo-doc-habilitacao-ps-md',
  templateUrl: './anexo-doc-habilitacao-ps-md.component.html',
  styleUrls: ['./anexo-doc-habilitacao-ps-md.component.css']
})
export class AnexoDocHabilitacaoPsMdComponent implements OnInit {

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  formData: FormGroup;
  _registroId: string;

  constructor(private fb: FormBuilder,
              private _httpAnexo: AnexosPessoalHabilitacaoHttpService,
              private notifyMsg: NotifyMessageService,
              private spinnerService: NgxSpinnerService) {
  }

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
    }
  }

  ngOnInit() {
  }

  showModal() {
    this.modal.show();
  }

  uploadAnexos(files: FileList) {
    if (!files.length) {
      return;
    }
    this.spinnerService.show();
    this.modal.hide();
    this._httpAnexo.create(this._registroId, files).subscribe((resp) => {
      this.spinnerService.hide();
      this.notifyMsg.success('Documento enviado com sucesso!');
      this.onSuccess.emit(resp);
    }, error => {
      this.spinnerService.hide();
      this.onError.emit(error);
      this.notifyMsg.error(error.error.message);
    });

  }

}
