import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {AnexosPessoalExibirHttpService} from '../../../../services/http/anexos-pessoal-exibir-http.service';
import {VariadosService} from '../../../../services/variados.service';
import {HttpErrorResponse} from '@angular/common/http';
import {NotifyMessageService} from '../../../../services/notify-message.service';

@Component({
  selector: 'app-anexo-doc-exibir-md',
  templateUrl: './anexo-doc-exibir-md.component.html',
  styleUrls: ['./anexo-doc-exibir-md.component.css']
})
export class AnexoDocExibirMdComponent implements OnInit {

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  @ViewChild(ModalLgComponent) modal: ModalLgComponent;
  _registroId: string;
  dadosDoc = [];

  constructor(private _http: AnexosPessoalExibirHttpService,
              public variados: VariadosService,
              private notifyMsg: NotifyMessageService) {
  }

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this.listarAnexos();
    }
  }

  ngOnInit() {
    this.listarAnexos();
  }

  listarAnexos() {
    this._http.show(this._registroId).subscribe((resp) => {
      this.dadosDoc = resp.data;
    });
  }

  showModal() {
    this.modal.show();
  }

  showDel(id_base64: any) {
    this._http.destroy(atob(id_base64)).subscribe(() => {
      this.modal.hide();
      this.onSuccess.emit();
    }, error => this.notifyMsg.error(error.error.message));
  }
}
