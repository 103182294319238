import {Component, OnInit} from '@angular/core';
import {AnaliseProcessoHttpService} from '../../../../services/http/analise-processo-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-analise-processo',
  templateUrl: './analise-processo.component.html',
  styleUrls: ['./analise-processo.component.css']
})
export class AnaliseProcessoComponent implements OnInit {

  analises = [];

  constructor(private _http: AnaliseProcessoHttpService,
              private authService: AuthService) {
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  ngOnInit() {
    this.listarAnalises();
  }

  listarAnalises() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.analises = resp.data;
    });
  }

}
