import {Component, OnInit} from '@angular/core';
import {VariadosService} from '../../../../services/variados.service';
import {TreinamentoHttpService} from '../../../../services/http/treinamento-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-treinamento',
  templateUrl: './treinamento.component.html',
  styleUrls: ['./treinamento.component.css']
})
export class TreinamentoComponent implements OnInit {

  dados = [];

  constructor(public variados: VariadosService,
              private _http: TreinamentoHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }


}
