import {Component, OnInit} from '@angular/core';
import {EnsaioProficienciaHttpService} from '../../../../services/http/ensaio-proficiencia-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-ensaio-proficiencia',
  templateUrl: './ensaio-proficiencia.component.html',
  styleUrls: ['./ensaio-proficiencia.component.css']
})
export class EnsaioProficienciaComponent implements OnInit {

  dados = [];

  constructor(private _http: EnsaioProficienciaHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
