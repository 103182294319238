import {Component, OnInit} from '@angular/core';
import {RiscoImparcialidadeHttpService} from '../../../../services/http/risco-imparcialidade-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-reg-risco-imparcialidade',
  templateUrl: './reg-risco-imparcialidade.component.html',
  styleUrls: ['./reg-risco-imparcialidade.component.css']
})
export class RegRiscoImparcialidadeComponent implements OnInit {

  dados = [];

  constructor(private _http: RiscoImparcialidadeHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    //posso cirar uma função
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
