import {Component, OnInit} from '@angular/core';
import {AvaliacaoDesempenhoHttpService} from '../../../../services/http/avaliacao-desempenho-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-avaliacao-desempenho',
  templateUrl: './avaliacao-desempenho.component.html',
  styleUrls: ['./avaliacao-desempenho.component.css']
})
export class AvaliacaoDesempenhoComponent implements OnInit {

  dados = [];

  constructor(private _http: AvaliacaoDesempenhoHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
