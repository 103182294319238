import { Component, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { AuthService } from "../../../../services/auth.service";
import { EscolherEmpresaModalComponent } from "../../escolher-empresa-modal/escolher-empresa-modal.component";
import { BoxAlertaEmpresaMdComponent } from "../box-alerta-empresa-md/box-alerta-empresa-md.component";
import { SelectAnexoGrupoHttpService } from "../../../../services/http/select-anexo-grupo-http.service";
import { MultiAnexosHttpService } from "../../../../services/http/multi-anexos-http.service";
import { MultiAnexosInvalidadosMdDComponent } from "../multi-anexos-invalidados-md-d/multi-anexos-invalidados-md-d.component";
import { MultiAnexosMdEComponent } from "../multi-anexos-md-e/multi-anexos-md-e.component";
import { MultiAnexosMdPDFComponent } from "../multi-anexos-md-pdf/multi-anexos-md-pdf.component";
import { DadosVencidosComponent } from "../dados-vencidos/dados-vencidos.component";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import { Observable, forkJoin } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import * as moment from "moment";
import { Router } from "@angular/router";

// declare var $: any;

@Component({
  selector: "app-dashboard-index",
  templateUrl: "./dashboard-index.component.html",
  styleUrls: ["./dashboard-index.component.css"],
})
export class DashboardIndexComponent implements OnInit {
  dadosGrupo: Observable<Array<any>>;
  formatedGrupo: Observable<Array<any>>;
  dadosSubgrupo: Observable<Array<any>>;
  pastasCompletas: Observable<Array<any>>;
  empresa_id: any;
  Object = Object;
  registroId: string;

  regex = /(?:\.([^.]+))?$/;
  validExtensions = ["PDF", "DOCX", "DOC", "XLS", "XLSX"];

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(EscolherEmpresaModalComponent)
  modalEmpresa: EscolherEmpresaModalComponent;
  @ViewChild(MultiAnexosMdEComponent) modalE: MultiAnexosMdEComponent;
  @ViewChild(MultiAnexosMdPDFComponent) modalPDF: MultiAnexosMdPDFComponent;
  @ViewChild(DadosVencidosComponent) dadosVencidos: DadosVencidosComponent;
  @ViewChild(BoxAlertaEmpresaMdComponent)
  boxAlertaEmpresa: BoxAlertaEmpresaMdComponent;

  @ViewChild(MultiAnexosInvalidadosMdDComponent)
  modalInvalidoD: MultiAnexosInvalidadosMdDComponent;
  // @ViewChild(MultiAnexosInvalidadosMdEComponent) modalAnexoReplace: MultiAnexosInvalidadosMdEComponent;
  // @ViewChild(MultiAnexosUpdateDocMdEComponent) modalAnexoRemove: MultiAnexosUpdateDocMdEComponent;

  constructor(
    private grupoHttp: SelectAnexoGrupoHttpService,
    private anexoHttp: MultiAnexosHttpService,
    private authService: AuthService,
    private notifyMsg: NotifyMessageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.listAnexos();
  }

  listAnexos() {
    this.empresa_id = { value: this.authService.getEmpresa(), disabled: false };

    this.anexoHttp
      .filterValidoPorEmpresa(this.empresa_id.value)
      .subscribe((response) => {
        /* CÁLCULO DO STATUS DE PENDÊNCIA */

        // Filter de pastas sem nenhum anexo validado
        response.data = response.data.filter((pasta) => {
          let vazia = true;
          pasta.subgrupos.forEach((subgrupo) => {
            // Filter dos Registros
            subgrupo.anexos = subgrupo.anexos.filter((registro) => {
              if (registro.empresa_id != this.empresa_id.value) return false; // Filtra por empresa
              if (registro.status != 3) return false; // Filtra os registros válidos
              return true;
            });

            if (subgrupo.anexos.length > 0) vazia = false;
          });
          return !vazia;
        });

        // Map das pastas
        this.pastasCompletas = response.data.map((pasta) => {
          // Map das Subpastas
          pasta.subgrupos.map((subpasta) => {
            // Filter dos Registros
            subpasta.anexos = subpasta.anexos.filter((registro) => {
              if (registro.empresa_id != this.empresa_id.value) return false; // Filtra por empresa
              if (registro.status != 3) return false; // Filtra os registros válidos
              return true;
            });

            // Map dos Registros
            subpasta.anexos.map((registro) => {
              registro.action_needed = true;
              registro.data_lembrete = "";

              // Realiza o cálculo do período de lembrete
              if (
                registro.periodo_lembrete !== null &&
                registro.data_proxima_acao !== null
              ) {
                registro.data_lembrete = moment(
                  registro.data_proxima_acao,
                  "YYYY-MM-DD"
                )
                  .subtract(registro.periodo_lembrete, "d")
                  .format("YYYY-MM-DD");

                if (moment().isSameOrBefore(registro.data_lembrete)) {
                  registro.action_needed = "Em dia";
                } else if (
                  moment().isBetween(
                    registro.data_lembrete,
                    moment(registro.data_proxima_acao).add(1, "d")
                  )
                ) {
                  registro.action_needed = "A vencer";
                } else {
                  registro.action_needed = "Vencido";
                }
              } else {
                if (registro.periodo_lembrete == null) {
                  registro.periodo_lembrete = false;
                }

                // Se não há data limite, o documento está em dia (?)
                registro.action_needed = "Em dia";
              }
              return registro;
            });
          });
        });

        this.pastasCompletas = response.data;
      });
  }

  onliberarModal() {
    this.boxAlertaEmpresa.showModal();
  }

  showUpdatePedente(id_base64: any) {
    //this.registroId = atob(id_base64);
    this.registroId = id_base64;
    this.modalE.showModal();
  }

  showPDF(id_base64: any) {
    this.registroId = id_base64;
    this.modalPDF.showModal();
  }

  showAnexoReplace(anexo_id: any) {
    // let route = this.router.config.find((r) => r.path === "anexos/incluir");
    // route.data = { param_anexo_id: anexo_id };
    this.router.navigateByUrl(`/anexos/substituir/${anexo_id}`);
  }
  showAnexoDel(id_base64: any) {
    this.registroId = id_base64;
    this.modalInvalidoD.showModal();
  }

  onSuccessDeleteInvalido($event: any) {
    this.listAnexos();
    this.notifyMsg.success("Registro excluído com sucesso.");
  }

  onSuccessUpdatePedente($event: any) {
    this.dadosVencidos.dadosVencidos();
    this.listAnexos();
  }
}
