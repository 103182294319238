import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnexosPessoalContratoHttpService {

  search: string;
  private baseApi = `${environment.api.url}`;

  constructor(private http: HttpClient) {
  }


  create(pessoalId, files: FileList): Observable<any> {
    const formData = new FormData();
    const filesArray = Array.from(files);

    filesArray.forEach((file) => {
      formData.append('anexos[]', file);
    });

    return this.http.post<any>(this.getBaseUrl(pessoalId), formData);
  }


  private getBaseUrl(pessoalId: number, anexoId: number = null): string {
    let baseUrl = `${this.baseApi}/documentacao_pessoal/${pessoalId}/anexar_contrato`;
    if (anexoId) {
      baseUrl += `/${anexoId}`;
    }
    return baseUrl;
  }
}
