import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TreinamentoHttpService {

  search: string;
  private baseUrl = `${environment.api.url}/treinamento`;

  constructor(private http: HttpClient) {
  }

  /* exclusivo para busca*/
  listSearch(search): Observable<any> {
    const params = new HttpParams({
      fromObject: ({search: search ? search : ''})
    });
    return this.http.get(this.baseUrl, {params});
  }

  /* listar por empresa e usuario*/
  filterCompanyUser(empresaId, usuarioId): Observable<any> {
    return this.http.get(`${this.baseUrl}/filter/${empresaId}/${usuarioId}`);
  }

  index(): Observable<any> {
    return this.http.get(this.baseUrl);
  }

  show(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  store(data): Observable<any> {
    return this.http.post(this.baseUrl, data);
  }

  update(id: string, data): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}`, data);
  }

  destroy(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
