import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {ProgramacaoHttpService} from '../../../../services/http/programacao-http.service';
import {AuthService} from '../../../../services/auth.service';
import {ProgramacaoModalEComponent} from '../programacao-modal-e/programacao-modal-e.component';
import {ProgramacaoModalDComponent} from '../programacao-modal-d/programacao-modal-d.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsLocaleService} from 'ngx-bootstrap';

@Component({
  selector: 'app-programacao-c',
  templateUrl: './programacao-c.component.html',
  styleUrls: ['./programacao-c.component.css']
})
export class ProgramacaoCComponent implements OnInit {

  programacoes = [];
  formData: FormGroup;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(ProgramacaoModalEComponent) programacaoModalE: ProgramacaoModalEComponent;
  @ViewChild(ProgramacaoModalDComponent) modalD: ProgramacaoModalDComponent;

  constructor(private fb: FormBuilder,
              private _http: ProgramacaoHttpService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService,
              private spinnerService: NgxSpinnerService,
              private datepicker: BsLocaleService) {
    datepicker.use('pt-BR');
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  /*apenas p/ ediação*/
  registroId: string;

  ngOnInit() {
    this.listarDados();
    this.formFields();
  }

  onSubmitInsert() {
    // this.spinnerService.show();
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarDados();
          // this.spinnerService.hide();
        }, error => {
          this.notifyMsg.error(error.error.message);
          // this.spinnerService.hide();
        });
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarDados() {
    //this.spinnerService.show();
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.programacoes = resp.data;
      this.spinnerService.hide();
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.programacaoModalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }


  private formFields() {
    this.formData = this.fb.group({
      descricao: [null, [Validators.required]],
      d_data: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }
}
