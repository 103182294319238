import {Component, OnInit} from '@angular/core';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';
import {VariadosService} from '../../../../services/variados.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-pessoal',
  templateUrl: './pessoal.component.html',
  styleUrls: ['./pessoal.component.css']
})
export class PessoalComponent implements OnInit {

  dados = [];

  constructor(private _http: PessoalHttpService,
              private authService: AuthService,
              public variados: VariadosService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
