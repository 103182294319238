import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {MsgGeralHttpService} from '../../../../services/http/msg-geral-http.service';
import {VariadosService} from '../../../../services/variados.service';
import {AuthService} from '../../../../services/auth.service';
import {MsgPorEmpresaHttpService} from '../../../../services/http/msg-por-empresa-http.service';

@Component({
  selector: 'app-msg-por-empresa-c',
  templateUrl: './msg-por-empresa-c.component.html',
  styleUrls: ['./msg-por-empresa-c.component.css']
})
export class MsgPorEmpresaCComponent implements OnInit {

  formData: FormGroup;
  dados = [];

  constructor(private fb: FormBuilder,
              private notifyMsg: NotifyMessageService,
              private _http: MsgPorEmpresaHttpService,
              public variados: VariadosService,
              public authService: AuthService) {

  }

  ids = {
    gerenciadorId: this.authService.profile.id,
    empresaId: this.authService.getEmpresa()
  };

  ngOnInit() {
    this.formFields();
    this.listarDados();
  }

  listarDados() {
    this._http.filterCompanyUser(this.authService.getEmpresa()).subscribe((resp) => {
      this.dados = resp.data;
    });
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset({
            status: {value: true, disabled: false},
            users_id: {value: this.ids.gerenciadorId, disabled: false},
            empresa_id: {value: this.ids.empresaId, disabled: false}
          });
          this.listarDados();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  private formFields() {
    /**
     * dados que vem do formuláio html
     */
    this.formData = this.fb.group({
      mensagem: [null, [Validators.required]],
      status: [true, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]]
    });
  }
}
