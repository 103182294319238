import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AuthService} from '../../../../services/auth.service';
import {FornecedorHttpService} from '../../../../services/http/fornecedor-http.service';
import {FornecedorModalEComponent} from '../fornecedor-modal-e/fornecedor-modal-e.component';
import {FornecedorModalDComponent} from '../fornecedor-modal-d/fornecedor-modal-d.component';

declare var $: any;

@Component({
  selector: 'app-fornecedor-create',
  templateUrl: './fornecedor-create.component.html',
  styleUrls: ['./fornecedor-create.component.css']
})
export class FornecedorCreateComponent implements OnInit {

  dados = [];
  formData: FormGroup;
  registroId: string;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(FornecedorModalEComponent) modalE: FornecedorModalEComponent;
  @ViewChild(FornecedorModalDComponent) modalD: FornecedorModalDComponent;

  constructor(private fb: FormBuilder,
              private _http: FornecedorHttpService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService) {
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  ngOnInit() {
    $(function () {
      $('#telefone').mask('(99)9999-9999');
      $('#celular').mask('99999-9999');
    });
    this.listarDados();
    this.formFields();
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarDados();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarDados() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.dados = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  private formFields() {
    this.formData = this.fb.group({
      empresa: [null, [Validators.required]],
      telefone: [null, [Validators.required]],
      celular: [null],
      email: [null],
      produto_servico: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }
}
