import {Component, OnInit} from '@angular/core';
import {AquisicaoRecebimentoHttpService} from '../../../../services/http/aquisicao-recebimento-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-aquisicao-recebimento',
  templateUrl: './aquisicao-recebimento.component.html',
  styleUrls: ['./aquisicao-recebimento.component.css']
})
export class AquisicaoRecebimentoComponent implements OnInit {

  dados = [];

  constructor(private _http: AquisicaoRecebimentoHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
