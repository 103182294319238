import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GerenciadorCreateComponent } from "./components/pages/painel/gerenciador-create/gerenciador-create.component";
import { CalibracaoVerificacaoCreateComponent } from "./components/pages/painel/calibracao-verificacao-create/calibracao-verificacao-create.component";
import { PessoalCreateComponent } from "./components/pages/painel/pessoal-create/pessoal-create.component";
import { DashboardIndexComponent } from "./components/pages/painel/dashboard-index/dashboard-index.component";
import { EmpresaCreateComponent } from "./components/pages/painel/empresa-create/empresa-create.component";
import { RelacaoEquipamentoCreateComponent } from "./components/pages/painel/relacao-equipamento-create/relacao-equipamento-create.component";
import { AnaliseProcessoCreateComponent } from "./components/pages/painel/analise-processo-create/analise-processo-create.component";
import { RegRiscoImparcialidadeCComponent } from "./components/pages/painel/reg-risco-imparcialidade-c/reg-risco-imparcialidade-c.component";
import { ManutencaoPreventivaCComponent } from "./components/pages/painel/manutencao-preventiva-c/manutencao-preventiva-c.component";
import { InstalacaoEquipamentoCComponent } from "./components/pages/painel/instalacao-equipamento-c/instalacao-equipamento-c.component";
import { CondicaoAmbientalCComponent } from "./components/pages/painel/condicao-ambiental-c/condicao-ambiental-c.component";
import { FormTesteComponent } from "./components/pages/form-teste/form-teste.component";
import { EmpresaIndexComponent } from "./components/pages/painel/empresa-index/empresa-index.component";
import { EmpresaEditComponent } from "./components/pages/painel/empresa-edit/empresa-edit.component";
import { AuditoriaIComponent } from "./components/pages/painel/auditoria-i/auditoria-i.component";
import { PessoalIndexComponent } from "./components/pages/painel/pessoal-index/pessoal-index.component";
import { DadosOrganismoComponent } from "./components/pages/painel/dados-organismo/dados-organismo.component";
import { PendenciaCComponent } from "./components/pages/painel/pendencia-c/pendencia-c.component";
import { ProgramacaoCComponent } from "./components/pages/painel/programacao-c/programacao-c.component";
import { ObservacoesCComponent } from "./components/pages/painel/observacoes-c/observacoes-c.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { AuthGuard } from "./guards/auth.guard";
import { EstruturaLegalCComponent } from "./components/pages/painel/estrutura-legal-c/estrutura-legal-c.component";
import { GruposCComponent } from "./components/pages/painel/grupos-c/grupos-c.component";
import { SubGruposCComponent } from "./components/pages/painel/sub-grupos-c/sub-grupos-c.component";
import { TreinamentoCComponent } from "./components/pages/painel/treinamento-c/treinamento-c.component";
import { AvaliacaoDesempenhoCComponent } from "./components/pages/painel/avaliacao-desempenho-c/avaliacao-desempenho-c.component";
import { MonitoramentoProcessoCComponent } from "./components/pages/painel/monitoramento-processo-c/monitoramento-processo-c.component";
import { EnsaioProficienciaCComponent } from "./components/pages/painel/ensaio-proficiencia-c/ensaio-proficiencia-c.component";
import { FornecedorCreateComponent } from "./components/pages/painel/fornecedor-create/fornecedor-create.component";
import { AquisicaoRecebimentoCComponent } from "./components/pages/painel/aquisicao-recebimento-c/aquisicao-recebimento-c.component";
import { ValidacaoSoftwareCComponent } from "./components/pages/painel/validacao-software-c/validacao-software-c.component";
import { ReclamacaoApelacaoCComponent } from "./components/pages/painel/reclamacao-apelacao-c/reclamacao-apelacao-c.component";
import { AcaoCorretivaCComponent } from "./components/pages/painel/acao-corretiva-c/acao-corretiva-c.component";
import { DocumentacaoPessoalCComponent } from "./components/pages/painel/documentacao-pessoal-c/documentacao-pessoal-c.component";
import { ListaMestraCComponent } from "./components/pages/painel/lista-mestra-c/lista-mestra-c.component";
import { RetroalimentacaoCComponent } from "./components/pages/painel/retroalimentacao-c/retroalimentacao-c.component";
import { RefreshEmpresaComponent } from "./components/pages/refresh-empresa/refresh-empresa.component";
import { UploadsComponent } from "./uploads/uploads.component";
import { MsgGeralCComponent } from "./components/pages/painel/msg-geral-c/msg-geral-c.component";
import { MsgPorEmpresaCComponent } from "./components/pages/painel/msg-por-empresa-c/msg-por-empresa-c.component";
import { MapaAtividadeCComponent } from "./components/pages/painel/mapa-atividade-c/mapa-atividade-c.component";
import { MapaValidacaoCComponent } from "./components/pages/painel/mapa-validacao-c/mapa-validacao-c.component";
import { MultiAnexosComponent } from "./components/pages/painel/multi-anexos/multi-anexos.component";
import { MultiAnexosArquivadosComponent } from "./components/pages/painel/multi-anexos-a/multi-anexos-a.component";
import { MultiAnexosPendentesInvalidadoComponent } from "./components/pages/painel/multi-anexos-va/multi-anexos-va.component";
import { LogsCienteComponent } from "./components/pages/painel/logs-ciente/logs-ciente.component";
import { AjudaMdComponent } from "./components/pages/painel/ajuda-md/ajuda-md.component";

const routes: Routes = [
  {
    path: "upload",
    component: UploadsComponent,
  },
  {
    path: "loading",
    component: RefreshEmpresaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "teste",
    component: FormTesteComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "dashboard",
    component: DashboardIndexComponent,
    canActivate: [AuthGuard], //protegendo a rota
  },
  {
    path: "gerenciador",
    component: GerenciadorCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "calibracao",
    component: CalibracaoVerificacaoCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "pessoal",
    component: PessoalIndexComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "documentacao_pessoal/incluir",
    component: DocumentacaoPessoalCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "pessoa/incluir",
    component: PessoalCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "empresa",
    component: EmpresaIndexComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "empresa/incluir",
    component: EmpresaCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "empresa/editar/:id",
    component: EmpresaEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "equipamento",
    component: RelacaoEquipamentoCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "analise-processo",
    component: AnaliseProcessoCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "riscos-a-imparcialidade",
    component: RegRiscoImparcialidadeCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "mantencao-preventiva",
    component: ManutencaoPreventivaCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "monitoramento-equiamento",
    component: InstalacaoEquipamentoCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "monitoramento-ambiental",
    component: CondicaoAmbientalCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "auditoria",
    component: AuditoriaIComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "organismo",
    component: DadosOrganismoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "pendencia/incluir",
    component: PendenciaCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "programacao/incluir",
    component: ProgramacaoCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "observacao/incluir",
    component: ObservacoesCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "estrutura-legal/incluir",
    component: EstruturaLegalCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "grupos/incluir",
    component: GruposCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sub-grupos/incluir",
    component: SubGruposCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "treinamento/incluir",
    component: TreinamentoCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "avaliacao-desempenho/incluir",
    component: AvaliacaoDesempenhoCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "monitoramento-processo/incluir",
    component: MonitoramentoProcessoCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ensaio-proficiencia/incluir",
    component: EnsaioProficienciaCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fornecedor/incluir",
    component: FornecedorCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fornecedor/incluir",
    component: FornecedorCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "aquisicao-recebimento/incluir",
    component: AquisicaoRecebimentoCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "validacao-software/incluir",
    component: ValidacaoSoftwareCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reclamacao-apelacao/incluir",
    component: ReclamacaoApelacaoCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "acao_corretiva/incluir",
    component: AcaoCorretivaCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "lista-mestra/incluir",
    component: ListaMestraCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "retroalimentacao/incluir",
    component: RetroalimentacaoCComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'anexos/incluir', component: AddAnexoEmpresaComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: "anexos/incluir",
    component: MultiAnexosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "anexos/substituir/:id",
    component: MultiAnexosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "anexos/arquivados",
    component: MultiAnexosArquivadosComponent,
  },
  {
    path: "anexos/pendenteseinvalidados",
    component: MultiAnexosPendentesInvalidadoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "mensagem-geral/send",
    component: MsgGeralCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "mensagem-empresa/send",
    component: MsgPorEmpresaCComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "mapa/atividade/incluir",
    component: MapaAtividadeCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "mapa/validacao/incluir",
    component: MapaValidacaoCComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "logs/aceito",
    component: LogsCienteComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'ajuda', component: AjudaMdComponent,
  //   canActivate: [AuthGuard]
  // },

  // {
  //   path: 'ogs/acesso', component: MapaValidacaoCComponent,
  //   canActivate: [AuthGuard]
  // },

  {
    /*Pagina principal*/
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
