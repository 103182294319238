import {Component, OnInit} from '@angular/core';
import {EmpresaHttpService} from '../../../../services/http/empresa-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-dados-organismo',
  templateUrl: './dados-organismo.component.html',
  styleUrls: ['./dados-organismo.component.css']
})
export class DadosOrganismoComponent implements OnInit {

  organismo = <any>[];

  constructor(private _http: EmpresaHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.dadosOrganismo();
  }

  dadosOrganismo() {
    this._http.show(this.authService.getEmpresa()).subscribe((resp) => {
      this.organismo = resp.data;
    });
  }

}
