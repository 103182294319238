import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MultiAnexosHttpService {
  private baseUrl = `${environment.api.url}/multi-anexos`;

  constructor(private http: HttpClient) {}

  uploadMultiDoc(formData): Observable<any> {
    return this.http.post<any>(this.baseUrl, formData);
  }

  uploadMultiDoSet(formData): Observable<any> {
    return this.http.post<any>(this.baseUrl, formData);
  }

  /* exclusivo para busca*/
  listSearch(search): Observable<any> {
    const params = new HttpParams({
      fromObject: { search: search ? search : "" },
    });
    return this.http.get(this.baseUrl, { params });
  }

  /* listar por empresa*/
  filterCompany(empresaId): Observable<any> {
    return this.http.get(`${this.baseUrl}/filter/${empresaId}`);
  }

  filterPedente(empresaId): Observable<any> {
    return this.http.get(`${this.baseUrl}/filter-pedente/${empresaId}`);
  }

  filterInvalido(empresaId): Observable<any> {
    return this.http.get(`${this.baseUrl}/filter-invalido/${empresaId}`);
  }

  filterValido(empresaId): Observable<any> {
    return this.http.get(`${this.baseUrl}/filter-valido/${empresaId}`);
  }

  filterValidoPorGrupo(empresaId, grupoId): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/filter-valido-grupo/${empresaId}/${grupoId}`
    );
  }

  filterValidoPorEmpresa(empresaId): Observable<any> {
    return this.http.get(`${this.baseUrl}/filter-empresa/${empresaId}`);
  }

  filterArquivadoPorEmpresa(empresaId): Observable<any> {
    return this.http.get(`${this.baseUrl}/filter-arquivado/${empresaId}`);
  }

  filterVencidos(empresaId): Observable<any> {
    return this.http.get(`${this.baseUrl}/filter-vencidos/${empresaId}`);
  }

  index(): Observable<any> {
    return this.http.get(this.baseUrl);
  }

  show(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  store(data, id?:string): Observable<any> {
    return this.http.post(this.baseUrl.concat(`${id != null ? `/${id}/replace`:''}`), data);
  }

  update(id: string, data): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}`, data);
  }

  destroy(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  clearPDF(): Observable<any> {
    console.log(`${environment.web.url}/pdf/clear`);

    return this.http.get(`${environment.web.url}/pdf/clear`);
  }
}
