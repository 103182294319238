import {Component, NgModule, OnInit, ViewChild} from '@angular/core';
import {UsersHttpService} from '../../../../services/http/users-http.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {EmpresaHttpService} from '../../../../services/http/empresa-http.service';
import {VariadosService} from '../../../../services/variados.service';
import {GerenciadorModalEComponent} from '../gerenciador-modal-e/gerenciador-modal-e.component';
import {GerenciadorModalDComponent} from '../gerenciador-modal-d/gerenciador-modal-d.component';
import {AuthService} from '../../../../services/auth.service';
import {Select2OptionData} from 'ng2-select2';

declare var $: any;

@Component({
  selector: 'app-gerenciador-create',
  templateUrl: './gerenciador-create.component.html',
  styleUrls: ['./gerenciador-create.component.css']
})
export class GerenciadorCreateComponent implements OnInit {

  dados = [];
  empresa = [];
  formData: FormGroup;
  registroId: string;

  empresasData: Array<Select2OptionData> =[];
  options: Select2Options;
  public value: string[] = [];

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(GerenciadorModalEComponent) modalE: GerenciadorModalEComponent;
  @ViewChild(GerenciadorModalDComponent) modalD: GerenciadorModalDComponent;

  constructor(private fb: FormBuilder,
              private _http: UsersHttpService,
              private _httpEmpresa: EmpresaHttpService,
              private notifyMsg: NotifyMessageService,
              public variado: VariadosService,
              public authService: AuthService) {
  }

  ngOnInit() {

    this.options = {
      multiple: true
    }

    $(function () {
      $('#celular').mask('(99)99999-9999');
    });

    this._httpEmpresa.index().subscribe((resp) => {
      this.empresasData = resp.data.map(({id,nome_fantasia})=>({id:String(id),text:String(nome_fantasia)}));
    });

    this.listarDados();
    this.formFields();
  }

  changed(data: {value: string[]}) {
    this.value = data.value;
  }

  onSubmitInsert() {
    
    this.formData.value.selectEmpresa = this.value;

    if (this.value.length != 0) {
      if (this.formData.valid) {
        this._http.store(this.formData.value)
          .subscribe(() => {
            this.formData.reset();
            this.notifyMsg.success('Registro incluido com sucesso.');
            this.listarDados();
            window.location.href = '/gerenciador';
          }, error => this.notifyMsg.error(error.message));
        
      } else {
        this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
      }
    } else {
      this.notifyMsg.info('Selecione ao menos uma empresa.');
    }
        
    
  }

  listarDados() {
    this._http.index().subscribe((resp) => {
      this.dados = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
  }

  private formFields() {
    this.formData = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.maxLength(15)]],
      cargo: [null],
      celular: [null],
      login: [null, [Validators.required, Validators.maxLength(30)]],
      nivel: [null, [Validators.required]],
      status: [1, [Validators.required]],
      selectEmpresa: [null]
    });
  }

  infor() {
    alert('Master: Gestor do sistema que possui todos os recursos, responsável por cadastrar novas contas, bloquear acessos, emitir comunicações gerais, entre outras atividades.\n\n' +
      'Mentor: Responsável por validar os anexos (verificar se os preenchimentos dos formulários estão corretos), editar as informações de atendimento aos critérios, as informações e orientações da súmula, entre outras atividades.\n\n' +
      'Consultor: Acesso para verificar as informações e os anexos dos seus clientes, registrar mudanças significativas, orientar o organismo, entre outras atividades.\n\n' +
      'Cliente: Verificar as informações postadas pelo mentor e pelo consultor, acompanhar o atendimento aos critérios de acreditação, anexar os arquivos gerados no período, registrar questionamentos ou explicações a questionamentos feitos, entre outras atividades...');
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }
}
