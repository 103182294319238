export const environment = {
  production: true,
  api: {
    protocol: "https",
    // host: 'app.iqcontroller.com',
    //host: '18.231.57.240',
    host: "api.sinfoniaecm.com.br",
    get url() {
      return `${this.protocol}://${this.host}/api`;
    },
  },
   backstage: {
      protocol: "https",
    // host: 'app.iqcontroller.com',
    //host: '18.231.57.240',
      host: "backstage.sinfoniaecm.com.br",
      get url() {
        return `${this.protocol}://${this.host}/`;
      },
  },
  web: {
    protocol: "https",
    host: "api.sinfoniaecm.com.br",
    get url() {
      return `${this.protocol}://${this.host}`;
    },
  },
};
