import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

/*módulos externos*/
import { defineLocale } from "ngx-bootstrap/chronos";
import { ptBrLocale } from "ngx-bootstrap/locale";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { NgxSpinnerModule } from "ngx-spinner";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { TooltipModule } from "ngx-bootstrap";

/*módulos internos*/
import { AuthService } from "./services/auth.service";
import { AppComponent } from "./app.component";
import { ModalComponent } from "./components/bootstrap/modal/modal.component";
import { ModalLgComponent } from "./components/bootstrap/modal-lg/modal-lg.component";
import { ModalSmComponent } from "./components/bootstrap/modal-sm/modal-sm.component";
import { GerenciadorCreateComponent } from "./components/pages/painel/gerenciador-create/gerenciador-create.component";
import { AppRoutingModule } from "./app-routing.module";
import { CalibracaoVerificacaoCreateComponent } from "./components/pages/painel/calibracao-verificacao-create/calibracao-verificacao-create.component";
import { PessoalCreateComponent } from "./components/pages/painel/pessoal-create/pessoal-create.component";
import { DashboardIndexComponent } from "./components/pages/painel/dashboard-index/dashboard-index.component";
import { EmpresaCreateComponent } from "./components/pages/painel/empresa-create/empresa-create.component";
import { RelacaoEquipamentoCreateComponent } from "./components/pages/painel/relacao-equipamento-create/relacao-equipamento-create.component";
import { AnaliseProcessoCreateComponent } from "./components/pages/painel/analise-processo-create/analise-processo-create.component";
import { RegRiscoImparcialidadeCComponent } from "./components/pages/painel/reg-risco-imparcialidade-c/reg-risco-imparcialidade-c.component";
import { ManutencaoPreventivaCComponent } from "./components/pages/painel/manutencao-preventiva-c/manutencao-preventiva-c.component";
import { InstalacaoEquipamentoCComponent } from "./components/pages/painel/instalacao-equipamento-c/instalacao-equipamento-c.component";
import { CondicaoAmbientalCComponent } from "./components/pages/painel/condicao-ambiental-c/condicao-ambiental-c.component";
import { FormTesteComponent } from "./components/pages/form-teste/form-teste.component";
import { EmpresaIndexComponent } from "./components/pages/painel/empresa-index/empresa-index.component";
import { EmpresaEditComponent } from "./components/pages/painel/empresa-edit/empresa-edit.component";
import { AuditoriaIComponent } from "./components/pages/painel/auditoria-i/auditoria-i.component";
import { EducacionalIComponent } from "./components/pages/painel/educacional-i/educacional-i.component";
import { PessoalIndexComponent } from "./components/pages/painel/pessoal-index/pessoal-index.component";
import { NavbarPrincipalComponent } from "./components/bootstrap/navbar-principal/navbar-principal.component";
import { DadosOrganismoComponent } from "./components/pages/painel/dados-organismo/dados-organismo.component";
import { DadosVencidosComponent } from "./components/pages/painel/dados-vencidos/dados-vencidos.component";
import { MapaQualidadeComponent } from "./components/pages/painel/mapa-qualidade/mapa-qualidade.component";
import { EstruturaLegalComponent } from "./components/pages/painel/estrutura-legal/estrutura-legal.component";
import { PendenciaComponent } from "./components/pages/painel/pendencia/pendencia.component";
import { PendenciaCComponent } from "./components/pages/painel/pendencia-c/pendencia-c.component";
import { ProgramacaoComponent } from "./components/pages/painel/programacao/programacao.component";
import { ProgramacaoCComponent } from "./components/pages/painel/programacao-c/programacao-c.component";
import { ObservacoesComponent } from "./components/pages/painel/observacoes/observacoes.component";
import { ObservacoesCComponent } from "./components/pages/painel/observacoes-c/observacoes-c.component";
import { EstruturaLegalCComponent } from "./components/pages/painel/estrutura-legal-c/estrutura-legal-c.component";
import { GruposCComponent } from "./components/pages/painel/grupos-c/grupos-c.component";
import { SubGruposCComponent } from "./components/pages/painel/sub-grupos-c/sub-grupos-c.component";
import { environment } from "../environments/environment";
import { LoginComponent } from "./components/pages/login/login.component";
import { RefreshTokenInterceptorService } from "./services/refresh-token-interceptor.service";
import { TreinamentoCComponent } from "./components/pages/painel/treinamento-c/treinamento-c.component";
import { AvaliacaoDesempenhoCComponent } from "./components/pages/painel/avaliacao-desempenho-c/avaliacao-desempenho-c.component";
import { MonitoramentoProcessoCComponent } from "./components/pages/painel/monitoramento-processo-c/monitoramento-processo-c.component";
import { EnsaioProficienciaCComponent } from "./components/pages/painel/ensaio-proficiencia-c/ensaio-proficiencia-c.component";
import { FornecedorCreateComponent } from "./components/pages/painel/fornecedor-create/fornecedor-create.component";
import { AquisicaoRecebimentoCComponent } from "./components/pages/painel/aquisicao-recebimento-c/aquisicao-recebimento-c.component";
import { ValidacaoSoftwareCComponent } from "./components/pages/painel/validacao-software-c/validacao-software-c.component";
import { ReclamacaoApelacaoCComponent } from "./components/pages/painel/reclamacao-apelacao-c/reclamacao-apelacao-c.component";
import { AcaoCorretivaCComponent } from "./components/pages/painel/acao-corretiva-c/acao-corretiva-c.component";
import { DocumentacaoPessoalCComponent } from "./components/pages/painel/documentacao-pessoal-c/documentacao-pessoal-c.component";
import { PessoalComponent } from "./components/pages/painel/pessoal/pessoal.component";
import { DocumentacaoPessoalComponent } from "./components/pages/painel/documentacao-pessoal/documentacao-pessoal.component";
import { AvaliacaoDesempenhoComponent } from "./components/pages/painel/avaliacao-desempenho/avaliacao-desempenho.component";
import { TreinamentoComponent } from "./components/pages/painel/treinamento/treinamento.component";
import { MonitoramentoProcessoComponent } from "./components/pages/painel/monitoramento-processo/monitoramento-processo.component";
import { RegRiscoImparcialidadeComponent } from "./components/pages/painel/reg-risco-imparcialidade/reg-risco-imparcialidade.component";
import { CalibracaoVerificacaoComponent } from "./components/pages/painel/calibracao-verificacao/calibracao-verificacao.component";
import { EnsaioProficienciaComponent } from "./components/pages/painel/ensaio-proficiencia/ensaio-proficiencia.component";
import { RelacaoEquipamentoComponent } from "./components/pages/painel/relacao-equipamento/relacao-equipamento.component";
import { ManutencaoPreventivaComponent } from "./components/pages/painel/manutencao-preventiva/manutencao-preventiva.component";
import { FornecedorComponent } from "./components/pages/painel/fornecedor/fornecedor.component";
import { AquisicaoRecebimentoComponent } from "./components/pages/painel/aquisicao-recebimento/aquisicao-recebimento.component";
import { InstalacaoEquipamentoComponent } from "./components/pages/painel/instalacao-equipamento/instalacao-equipamento.component";
import { ValidacaoSoftwareComponent } from "./components/pages/painel/validacao-software/validacao-software.component";
import { ReclamacaoApelacaoComponent } from "./components/pages/painel/reclamacao-apelacao/reclamacao-apelacao.component";
import { ListaMestraComponent } from "./components/pages/painel/lista-mestra/lista-mestra.component";
import { RetroalimentacaoComponent } from "./components/pages/painel/retroalimentacao/retroalimentacao.component";
import { ListaMestraCComponent } from "./components/pages/painel/lista-mestra-c/lista-mestra-c.component";
import { RetroalimentacaoCComponent } from "./components/pages/painel/retroalimentacao-c/retroalimentacao-c.component";
import { AcaoCorretivaComponent } from "./components/pages/painel/acao-corretiva/acao-corretiva.component";
import { PendenciaModalEComponent } from "./components/pages/painel/pendencia-modal-e/pendencia-modal-e.component";
import { ProgramacaoModalEComponent } from "./components/pages/painel/programacao-modal-e/programacao-modal-e.component";
import { ObservacoesModalEComponent } from "./components/pages/painel/observacoes-modal-e/observacoes-modal-e.component";
import { EstruturaLegalModalEComponent } from "./components/pages/painel/estrutura-legal-modal-e/estrutura-legal-modal-e.component";
import { GruposModalEComponent } from "./components/pages/painel/grupos-modal-e/grupos-modal-e.component";
import { SubGruposModalEComponent } from "./components/pages/painel/sub-grupos-modal-e/sub-grupos-modal-e.component";
import { PessoalModalEComponent } from "./components/pages/painel/pessoal-modal-e/pessoal-modal-e.component";
import { CalibracaoVerificacaoModalCComponent } from "./components/pages/painel/calibracao-verificacao-modal-c/calibracao-verificacao-modal-c.component";
import { TreinamentoModalEComponent } from "./components/pages/painel/treinamento-modal-e/treinamento-modal-e.component";
import { AvaliacaoDesempenhoModalEComponent } from "./components/pages/painel/avaliacao-desempenho-modal-e/avaliacao-desempenho-modal-e.component";
import { MonitoramentoProcessoModalEComponent } from "./components/pages/painel/monitoramento-processo-modal-e/monitoramento-processo-modal-e.component";
import { RegRiscoImparcialidadeModalEComponent } from "./components/pages/painel/reg-risco-imparcialidade-modal-e/reg-risco-imparcialidade-modal-e.component";
import { EnsaioProficienciaModalEComponent } from "./components/pages/painel/ensaio-proficiencia-modal-e/ensaio-proficiencia-modal-e.component";
import { RelacaoEquipamentoModalEComponent } from "./components/pages/painel/relacao-equipamento-modal-e/relacao-equipamento-modal-e.component";
import { ManutencaoPreventivaModalEComponent } from "./components/pages/painel/manutencao-preventiva-modal-e/manutencao-preventiva-modal-e.component";
import { FornecedorModalEComponent } from "./components/pages/painel/fornecedor-modal-e/fornecedor-modal-e.component";
import { AquisicaoRecebimentoModalEComponent } from "./components/pages/painel/aquisicao-recebimento-modal-e/aquisicao-recebimento-modal-e.component";
import { InstalacaoEquipamentoModalEComponent } from "./components/pages/painel/instalacao-equipamento-modal-e/instalacao-equipamento-modal-e.component";
import { CondicaoAmbientalModalEComponent } from "./components/pages/painel/condicao-ambiental-modal-e/condicao-ambiental-modal-e.component";
import { ValidacaoSoftwareModalComponent } from "./components/pages/painel/validacao-software-modal/validacao-software-modal.component";
import { ReclamacaoApelacaoModalEComponent } from "./components/pages/painel/reclamacao-apelacao-modal-e/reclamacao-apelacao-modal-e.component";
import { ListaMestraModalEComponent } from "./components/pages/painel/lista-mestra-modal-e/lista-mestra-modal-e.component";
import { RetroalimentacaoModalEComponent } from "./components/pages/painel/retroalimentacao-modal-e/retroalimentacao-modal-e.component";
import { AcaoCorretivaModalEComponent } from "./components/pages/painel/acao-corretiva-modal-e/acao-corretiva-modal-e.component";
import { AnaliseProcessoModalEComponent } from "./components/pages/painel/analise-processo-modal-e/analise-processo-modal-e.component";
import { PendenciaModalDComponent } from "./components/pages/painel/pendencia-modal-d/pendencia-modal-d.component";
import { ProgramacaoModalDComponent } from "./components/pages/painel/programacao-modal-d/programacao-modal-d.component";
import { ObservacoesModalDComponent } from "./components/pages/painel/observacoes-modal-d/observacoes-modal-d.component";
import { EstruturaLegalModalDComponent } from "./components/pages/painel/estrutura-legal-modal-d/estrutura-legal-modal-d.component";
import { GruposModalDComponent } from "./components/pages/painel/grupos-modal-d/grupos-modal-d.component";
import { SubGruposModalDComponent } from "./components/pages/painel/sub-grupos-modal-d/sub-grupos-modal-d.component";
import { PessoalModalDComponent } from "./components/pages/painel/pessoal-modal-d/pessoal-modal-d.component";
import { TreinamentoModalDComponent } from "./components/pages/painel/treinamento-modal-d/treinamento-modal-d.component";
import { EscolherEmpresaModalComponent } from "./components/pages/escolher-empresa-modal/escolher-empresa-modal.component";
import { EmpresaAtivaComponent } from "./components/pages/empresa-ativa/empresa-ativa.component";
import { RefreshEmpresaComponent } from "./components/pages/refresh-empresa/refresh-empresa.component";
import { DocumentacaoPessoalModalEComponent } from "./components/pages/painel/documentacao-pessoal-modal-e/documentacao-pessoal-modal-e.component";
import { DocumentacaoPessoalModalDComponent } from "./components/pages/painel/documentacao-pessoal-modal-d/documentacao-pessoal-modal-d.component";
import { AvaliacaoDesempenhoModalDComponent } from "./components/pages/painel/avaliacao-desempenho-modal-d/avaliacao-desempenho-modal-d.component";
import { MonitoramentoProcessoModalDComponent } from "./components/pages/painel/monitoramento-processo-modal-d/monitoramento-processo-modal-d.component";
import { RegRiscoImparcialidadeModalDComponent } from "./components/pages/painel/reg-risco-imparcialidade-modal-d/reg-risco-imparcialidade-modal-d.component";
import { ComboResponsavelComponent } from "./components/pages/combo-responsavel/combo-responsavel.component";
import { CalibracaoVerificacaoModalDComponent } from "./components/pages/painel/calibracao-verificacao-modal-d/calibracao-verificacao-modal-d.component";
import { EnsaioProficienciaModalDComponent } from "./components/pages/painel/ensaio-proficiencia-modal-d/ensaio-proficiencia-modal-d.component";
import { RelacaoEquipamentoModalDComponent } from "./components/pages/painel/relacao-equipamento-modal-d/relacao-equipamento-modal-d.component";
import { ManutencaoPreventivaModalDComponent } from "./components/pages/painel/manutencao-preventiva-modal-d/manutencao-preventiva-modal-d.component";
import { FornecedorModalDComponent } from "./components/pages/painel/fornecedor-modal-d/fornecedor-modal-d.component";
import { AquisicaoRecebimentoModalDComponent } from "./components/pages/painel/aquisicao-recebimento-modal-d/aquisicao-recebimento-modal-d.component";
import { InstalacaoEquipamentoModalDComponent } from "./components/pages/painel/instalacao-equipamento-modal-d/instalacao-equipamento-modal-d.component";
import { CondicaoAmbientalModalDComponent } from "./components/pages/painel/condicao-ambiental-modal-d/condicao-ambiental-modal-d.component";
import { ValidacaoSoftwareModalDComponent } from "./components/pages/painel/validacao-software-modal-d/validacao-software-modal-d.component";
import { ReclamacaoApelacaoModalDComponent } from "./components/pages/painel/reclamacao-apelacao-modal-d/reclamacao-apelacao-modal-d.component";
import { ListaMestraModalDComponent } from "./components/pages/painel/lista-mestra-modal-d/lista-mestra-modal-d.component";
import { RetroalimentacaoModalDComponent } from "./components/pages/painel/retroalimentacao-modal-d/retroalimentacao-modal-d.component";
import { AnaliseProcessoModalDComponent } from "./components/pages/painel/analise-processo-modal-d/analise-processo-modal-d.component";
import { AcaoCorretivaModalDComponent } from "./components/pages/painel/acao-corretiva-modal-d/acao-corretiva-modal-d.component";
import { GerenciadorModalEComponent } from "./components/pages/painel/gerenciador-modal-e/gerenciador-modal-e.component";
import { GerenciadorModalDComponent } from "./components/pages/painel/gerenciador-modal-d/gerenciador-modal-d.component";
import { AddAnexoEmpresaComponent } from "./components/pages/painel/add-anexo-empresa/add-anexo-empresa.component";
import { Ng2AnexoPessoalComponent } from "./components/pages/painel/ng2-anexo-pessoal/ng2-anexo-pessoal.component";
import { UploadsComponent } from "./uploads/uploads.component";
import { AnexoDocCurriculoPsMdComponent } from "./components/pages/painel/anexo-doc-curriculo-ps-md/anexo-doc-curriculo-ps-md.component";
import { AnexoDocContratoPsMdComponent } from "./components/pages/painel/anexo-doc-contrato-ps-md/anexo-doc-contrato-ps-md.component";
import { AnexoDocCnhPsMdComponent } from "./components/pages/painel/anexo-doc-cnh-ps-md/anexo-doc-cnh-ps-md.component";
import { AnexoDocCondutaPsMdComponent } from "./components/pages/painel/anexo-doc-conduta-ps-md/anexo-doc-conduta-ps-md.component";
import { AnexoDocCreaCarteiraPsMdComponent } from "./components/pages/painel/anexo-doc-crea-carteira-ps-md/anexo-doc-crea-carteira-ps-md.component";
import { AnexoDocCreaCertidaoPsMdComponent } from "./components/pages/painel/anexo-doc-crea-certidao-ps-md/anexo-doc-crea-certidao-ps-md.component";
import { AnexoDocImparcialidadePsMdComponent } from "./components/pages/painel/anexo-doc-imparcialidade-ps-md/anexo-doc-imparcialidade-ps-md.component";
import { AnexoDocHabilitacaoPsMdComponent } from "./components/pages/painel/anexo-doc-habilitacao-ps-md/anexo-doc-habilitacao-ps-md.component";
import { AnexoDocEpiPsMdComponent } from "./components/pages/painel/anexo-doc-epi-ps-md/anexo-doc-epi-ps-md.component";
import { MsgGeralCComponent } from "./components/pages/painel/msg-geral-c/msg-geral-c.component";
import { MsgPorEmpresaCComponent } from "./components/pages/painel/msg-por-empresa-c/msg-por-empresa-c.component";
import { AnaliseProcessoComponent } from "./components/pages/painel/analise-processo/analise-processo.component";
import { MapaAtividadeCComponent } from "./components/pages/painel/mapa-atividade-c/mapa-atividade-c.component";
import { MapaValidacaoCComponent } from "./components/pages/painel/mapa-validacao-c/mapa-validacao-c.component";
import { MapaAtividadeModalEComponent } from "./components/pages/painel/mapa-atividade-modal-e/mapa-atividade-modal-e.component";
import { MapaAtividadeModalDComponent } from "./components/pages/painel/mapa-atividade-modal-d/mapa-atividade-modal-d.component";
import { MapaValidacaoModalEComponent } from "./components/pages/painel/mapa-validacao-modal-e/mapa-validacao-modal-e.component";
import { MapaValidacaoModalDComponent } from "./components/pages/painel/mapa-validacao-modal-d/mapa-validacao-modal-d.component";
import { AnexoDocExibirMdComponent } from "./components/pages/painel/anexo-doc-exibir-md/anexo-doc-exibir-md.component";
import { MultiAnexosComponent } from "./components/pages/painel/multi-anexos/multi-anexos.component";
import { MultiAnexosArquivadosComponent } from "./components/pages/painel/multi-anexos-a/multi-anexos-a.component";
import { MultiAnexosPendentesInvalidadoComponent } from "./components/pages/painel/multi-anexos-va/multi-anexos-va.component";
import { AnexoValidoMdComponent } from "./components/pages/painel/anexo-valido-md/anexo-valido-md.component";
import { MultiAnexosMdEComponent } from "./components/pages/painel/multi-anexos-md-e/multi-anexos-md-e.component";
import { MultiAnexosMdPDFComponent } from "./components/pages/painel/multi-anexos-md-pdf/multi-anexos-md-pdf.component";
import { MultiAnexosMdDComponent } from "./components/pages/painel/multi-anexos-md-d/multi-anexos-md-d.component";
import { MultiAnexosInvalidadosMdEComponent } from "./components/pages/painel/multi-anexos-invalidados-md-e/multi-anexos-invalidados-md-e.component";
import { MultiAnexosInvalidadosMdDComponent } from "./components/pages/painel/multi-anexos-invalidados-md-d/multi-anexos-invalidados-md-d.component";
import { MultiAnexosUpdateDocMdEComponent } from "./components/pages/painel/multi-anexos-update-doc-md-e/multi-anexos-update-doc-md-e.component";
import { ModalXlComponent } from "./components/bootstrap/modal-xl/modal-xl.component";
import { BoxAlertaEmpresaMdComponent } from "./components/pages/painel/box-alerta-empresa-md/box-alerta-empresa-md.component";
import { ModalOffCloseComponent } from "./components/bootstrap/modal-off-close/modal-off-close.component";
import { LogsCienteComponent } from "./components/pages/painel/logs-ciente/logs-ciente.component";
import { AjudaMdComponent } from "./components/pages/painel/ajuda-md/ajuda-md.component";
import { Select2Module } from "ng2-select2";

defineLocale("pt-br", ptBrLocale);

/*criado para configurar a biblioteca*/
export function jwtFactory(authService: AuthService) {
  return {
    whitelistedDomains: [new RegExp(`${environment.api.host}/*`)],
    tokenGetter: () => {
      return authService.getToken();
    },
  };
}

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    //containerClass: 'theme-red',
    //containerClass: 'theme-green',
    containerClass: "theme-orange",
    //containerClass: 'theme-blue',
    // dateInputFormat: 'YYYY-MM-DD',
    //displayMonths: 2,
    //rangeInputFormat: 'L',
    // monthTitle: 'MMMM',
    // yearTitle: 'YYYY',
    // dayLabel: 'D',
    // monthLabel: 'MMMM',
    // yearLabel: 'YYYY',
    // weekNumbers: 'w'
  });
}

@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    ModalLgComponent,
    ModalSmComponent,
    GerenciadorCreateComponent,
    CalibracaoVerificacaoCreateComponent,
    PessoalCreateComponent,
    DashboardIndexComponent,
    EmpresaCreateComponent,
    RelacaoEquipamentoCreateComponent,
    AnaliseProcessoCreateComponent,
    RegRiscoImparcialidadeCComponent,
    ManutencaoPreventivaCComponent,
    InstalacaoEquipamentoCComponent,
    CondicaoAmbientalCComponent,
    FormTesteComponent,
    EmpresaIndexComponent,
    EmpresaEditComponent,
    AuditoriaIComponent,
    EducacionalIComponent,
    PessoalIndexComponent,
    NavbarPrincipalComponent,
    DadosOrganismoComponent,
    DadosVencidosComponent,
    MapaQualidadeComponent,
    EstruturaLegalComponent,
    PendenciaComponent,
    PendenciaCComponent,
    ProgramacaoComponent,
    ProgramacaoCComponent,
    ObservacoesComponent,
    ObservacoesCComponent,
    EstruturaLegalCComponent,
    GruposCComponent,
    SubGruposCComponent,
    LoginComponent,
    TreinamentoCComponent,
    AvaliacaoDesempenhoCComponent,
    MonitoramentoProcessoCComponent,
    EnsaioProficienciaCComponent,
    FornecedorCreateComponent,
    AquisicaoRecebimentoCComponent,
    ValidacaoSoftwareCComponent,
    ReclamacaoApelacaoCComponent,
    AcaoCorretivaCComponent,
    DocumentacaoPessoalCComponent,
    PessoalComponent,
    DocumentacaoPessoalComponent,
    AvaliacaoDesempenhoComponent,
    TreinamentoComponent,
    MonitoramentoProcessoComponent,
    RegRiscoImparcialidadeComponent,
    CalibracaoVerificacaoComponent,
    EnsaioProficienciaComponent,
    RelacaoEquipamentoComponent,
    ManutencaoPreventivaComponent,
    FornecedorComponent,
    AquisicaoRecebimentoComponent,
    InstalacaoEquipamentoComponent,
    ValidacaoSoftwareComponent,
    ReclamacaoApelacaoComponent,
    ListaMestraComponent,
    RetroalimentacaoComponent,
    ListaMestraCComponent,
    RetroalimentacaoCComponent,
    AcaoCorretivaComponent,
    PendenciaModalEComponent,
    ProgramacaoModalEComponent,
    ObservacoesModalEComponent,
    EstruturaLegalModalEComponent,
    GruposModalEComponent,
    SubGruposModalEComponent,
    PessoalModalEComponent,
    CalibracaoVerificacaoModalCComponent,
    TreinamentoModalEComponent,
    AvaliacaoDesempenhoModalEComponent,
    MonitoramentoProcessoModalEComponent,
    RegRiscoImparcialidadeModalEComponent,
    EnsaioProficienciaModalEComponent,
    RelacaoEquipamentoModalEComponent,
    ManutencaoPreventivaModalEComponent,
    FornecedorModalEComponent,
    AquisicaoRecebimentoModalEComponent,
    InstalacaoEquipamentoModalEComponent,
    CondicaoAmbientalModalEComponent,
    ValidacaoSoftwareModalComponent,
    ReclamacaoApelacaoModalEComponent,
    ListaMestraModalEComponent,
    RetroalimentacaoModalEComponent,
    AcaoCorretivaModalEComponent,
    AnaliseProcessoModalEComponent,
    PendenciaModalDComponent,
    ProgramacaoModalDComponent,
    ObservacoesModalDComponent,
    EstruturaLegalModalDComponent,
    GruposModalDComponent,
    SubGruposModalDComponent,
    PessoalModalDComponent,
    TreinamentoModalDComponent,
    EscolherEmpresaModalComponent,
    EmpresaAtivaComponent,
    RefreshEmpresaComponent,
    DocumentacaoPessoalModalEComponent,
    DocumentacaoPessoalModalDComponent,
    AvaliacaoDesempenhoModalDComponent,
    MonitoramentoProcessoModalDComponent,
    RegRiscoImparcialidadeModalDComponent,
    ComboResponsavelComponent,
    CalibracaoVerificacaoModalDComponent,
    EnsaioProficienciaModalDComponent,
    RelacaoEquipamentoModalDComponent,
    ManutencaoPreventivaModalDComponent,
    FornecedorModalDComponent,
    AquisicaoRecebimentoModalDComponent,
    InstalacaoEquipamentoModalDComponent,
    CondicaoAmbientalModalDComponent,
    ValidacaoSoftwareModalDComponent,
    ReclamacaoApelacaoModalDComponent,
    ListaMestraModalDComponent,
    RetroalimentacaoModalDComponent,
    AnaliseProcessoModalDComponent,
    AcaoCorretivaModalDComponent,
    GerenciadorModalEComponent,
    GerenciadorModalDComponent,
    AddAnexoEmpresaComponent,
    Ng2AnexoPessoalComponent,
    UploadsComponent,
    AnexoDocCurriculoPsMdComponent,
    AnexoDocContratoPsMdComponent,
    AnexoDocCnhPsMdComponent,
    AnexoDocCondutaPsMdComponent,
    AnexoDocCreaCarteiraPsMdComponent,
    AnexoDocCreaCertidaoPsMdComponent,
    AnexoDocImparcialidadePsMdComponent,
    AnexoDocHabilitacaoPsMdComponent,
    AnexoDocEpiPsMdComponent,
    MsgGeralCComponent,
    MsgPorEmpresaCComponent,
    AnaliseProcessoComponent,
    MapaAtividadeCComponent,
    MapaValidacaoCComponent,
    MapaAtividadeModalEComponent,
    MapaAtividadeModalDComponent,
    MapaValidacaoModalEComponent,
    MapaValidacaoModalDComponent,
    AnexoDocExibirMdComponent,
    MultiAnexosComponent,
    MultiAnexosArquivadosComponent,
    MultiAnexosPendentesInvalidadoComponent,
    AnexoValidoMdComponent,
    MultiAnexosMdEComponent,
    MultiAnexosMdPDFComponent,
    MultiAnexosMdDComponent,
    MultiAnexosInvalidadosMdEComponent,
    MultiAnexosInvalidadosMdDComponent,
    MultiAnexosUpdateDocMdEComponent,
    ModalXlComponent,
    BoxAlertaEmpresaMdComponent,
    ModalOffCloseComponent,
    LogsCienteComponent,
    AjudaMdComponent,
  ],
  imports: [
    Select2Module,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtFactory,
        deps: [AuthService],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptorService,
      multi: true,
    },
    {
      provide: BsDatepickerConfig,
      useFactory: getDatepickerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
