import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auditoria-i',
  templateUrl: './auditoria-i.component.html',
  styleUrls: ['./auditoria-i.component.css']
})
export class AuditoriaIComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
