import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {InstalacaoEquipamentoHttpService} from '../../../../services/http/instalacao-equipamento-http.service';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-instalacao-equipamento-modal-e',
  templateUrl: './instalacao-equipamento-modal-e.component.html',
  styleUrls: ['./instalacao-equipamento-modal-e.component.css']
})
export class InstalacaoEquipamentoModalEComponent implements OnInit {

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  formData: FormGroup;
  dadosPessoal: Observable<Array<any>>; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
  _registroId: string;
  pessoaId: string;

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this._http.show(value).subscribe((resp) => {
          this.formData.patchValue(resp);
        }
      );
    }
  }

  constructor(private fb: FormBuilder,
              private _http: InstalacaoEquipamentoHttpService,
              private _httpPessoal: PessoalHttpService,
              private notifyMsg: NotifyMessageService) {
  }

  ngOnInit() {
    this.formFields();
    this.dadosPessoal = this._httpPessoal.index().pipe(map(resp => resp.data));
  }

  onSubmitUpdate() {
    this._http.update(this._registroId, this.formData.value)
      .subscribe(() => {
        this.modal.hide();
        this.onSuccess.emit();
      }, error => this.notifyMsg.error(error.error.message));
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    this.formData = this.fb.group({
      data: [null, [Validators.required]],
      condicao_instalacao: [null, [Validators.required]],
      condicao_equipamento: [null, [Validators.required]],
      responsavel: [null, [Validators.required]]
    });
  }

  /*comobox responsavel*/
  getPessoaId(id) {
    this.pessoaId = id.value;
  }
}
