import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ModalXlComponent } from "../../../bootstrap/modal-xl/modal-xl.component";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import { MultiAnexosHttpService } from "../../../../services/http/multi-anexos-http.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-multi-anexos-md-pdf",
  templateUrl: "./multi-anexos-md-pdf.component.html",
  styleUrls: ["./multi-anexos-md-pdf.component.css"],
})
export class MultiAnexosMdPDFComponent implements OnInit {
  _registroId: string;
  dados = <any>[];
  pdf: string = "";
  loading: boolean = true;

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();
  @ViewChild(ModalXlComponent) modal: ModalXlComponent;

  constructor(
    private notifyMsg: NotifyMessageService,
    private _http: MultiAnexosHttpService,
    private spinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer
  ) {}

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this.loading = true;
      this._registroId = value;
      this._http.show(value).subscribe((resp) => {
        this.dados = resp.data;
        this.pdf = environment.web.url + "/pdf/" + this.dados.id;
        this.loading = false;
      });
    }
  }

  getSafePdf(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.pdf);
  }

  ngOnInit() {}

  showModal() {
    this.modal.show();
  }

  clearFiles() {
    this.loading = true;
    this._http.clearPDF();
  }
}
