import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VariadosService {

  constructor() {
  }

  cargos = [
    {'id': 1, 'nome': 'Diretor Geral'},
    {'id': 2, 'nome': 'Responsável Técnico'},
    {'id': 3, 'nome': 'Representante da Qualidade'},
    {'id': 4, 'nome': 'Auxiliar Técnico'},
    {'id': 5, 'nome': 'Auxiliar Administrativo'},
    {'id': 6, 'nome': 'Estagiário'},
    {'id': 7, 'nome': 'TI'},
    {'id': 8, 'nome': 'Serviços Gerais'},
    {'id': 8, 'nome': 'Inspetor Técnico'}
  ];

  vinculos = [
    {'id': 1, 'nome': 'CLT'},
    {'id': 2, 'nome': 'Contrato'},
    {'id': 3, 'nome': 'Sócio'},
    {'id': 4, 'nome': 'Proprietário'}
  ];

  meses = [
    {'id': 1, 'nome': 'Janeiro'},
    {'id': 2, 'nome': 'Fevereiro'},
    {'id': 3, 'nome': 'Março'},
    {'id': 4, 'nome': 'Abril'},
    {'id': 5, 'nome': 'Maio'},
    {'id': 6, 'nome': 'Junho'},
    {'id': 7, 'nome': 'Julho'},
    {'id': 8, 'nome': 'Agosto'},
    {'id': 9, 'nome': 'Setembro'},
    {'id': 10, 'nome': 'Outubro'},
    {'id': 11, 'nome': 'Novembro'},
    {'id': 12, 'nome': 'Dezembro'}
  ];

  equipamentoStatus = [
    {'id': 1, 'nome': 'Incluso'},
    {'id': 2, 'nome': 'Excluso'}
  ];

  nivel = [
    {'id': 1, 'nome': 'Master'},
    {'id': 2, 'nome': 'Mentor'},
    {'id': 3, 'nome': 'Consultor'},
    {'id': 4, 'nome': 'Cliente'}
  ];

  status = [
    {id: '1', 'nome': 'Ativo'},
    {id: '0', 'nome': 'Inativo'}
  ];

  tipoDocumento = [
    {id: 1, 'nome': 'Currículo'},
    {id: 2, 'nome': 'Contrato'},
    {id: 3, 'nome': 'CNH/RG'},
    {id: 4, 'nome': 'Conduta'},
    {id: 5, 'nome': 'Carteira'},
    {id: 6, 'nome': 'Certidão'},
    {id: 7, 'nome': 'Imparcialidade'},
    {id: 8, 'nome': 'Habilitação'},
    {id: 9, 'nome': 'EPI'}
  ];


}
