import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PendenciaHttpService} from '../../../../services/http/pendencia-http.service';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {BsLocaleService} from 'ngx-bootstrap';

@Component({
  selector: 'app-pendencia-modal-e',
  templateUrl: './pendencia-modal-e.component.html',
  styleUrls: ['./pendencia-modal-e.component.css']
})
export class PendenciaModalEComponent implements OnInit {

  /*decorador de eventos p/ ser pego nos components filhos*/
  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  formData: FormGroup;
  _registroId: string;

  /*decorador de eventos p/ enviar dados dos components filhos para o pai*/
  // @Input()
  // registroId: string;

  /*decorador de eventos p/ enviar dados dos components filhos para o pai*/
  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this._http.show(value).subscribe((resp) => {
          this.formData.patchValue(resp.data);
        }
      );
    }
  }

  constructor(private fb: FormBuilder,
              private _http: PendenciaHttpService,
              private notifyMsg: NotifyMessageService,
              private datepicker: BsLocaleService) {
    datepicker.use('pt-BR');
  }

  ngOnInit() {
    this.formFields();
  }

  onSubmitUpdate() {
    this._http.update(this._registroId, this.formData.value)
      .subscribe(() => {
        this.modal.hide();
        this.onSuccess.emit();
      }, error => this.notifyMsg.error(error.error.message));
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    this.formData = this.fb.group({
      d_data: [null, [Validators.required]],
      descricao: [null, [Validators.required]]
    });
  }
}
