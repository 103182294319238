import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {EstruturaLegalHttpService} from '../../../../services/http/estrutura-legal-http.service';
import {AuthService} from '../../../../services/auth.service';
import {BsLocaleService} from 'ngx-bootstrap';
import {EstruturaLegalModalEComponent} from '../estrutura-legal-modal-e/estrutura-legal-modal-e.component';
import {EstruturaLegalModalDComponent} from '../estrutura-legal-modal-d/estrutura-legal-modal-d.component';

declare var $: any;

@Component({
  selector: 'app-estrutura-legal-c',
  templateUrl: './estrutura-legal-c.component.html',
  styleUrls: ['./estrutura-legal-c.component.css']
})
export class EstruturaLegalCComponent implements OnInit {

  dados = [];
  formData: FormGroup;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(EstruturaLegalModalEComponent) modalUpdate: EstruturaLegalModalEComponent;
  @ViewChild(EstruturaLegalModalDComponent) modalD: EstruturaLegalModalDComponent;

  constructor(private fb: FormBuilder,
              private _http: EstruturaLegalHttpService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService,
              private datepicker: BsLocaleService) {
    datepicker.use('pt-BR');
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  /*apenas p/ ediação*/
  registroId: string;

  ngOnInit() {
    $(function () {
      $('#vencimento').mask('00/00/0000');
    });

    this.listarDados();
    this.formFields();
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarDados();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarDados() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.dados = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalUpdate.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  private formFields() {
    this.formData = this.fb.group({
      documento: [null, [Validators.required]],
      vencimento: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }
}
