import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AuthService} from '../../../../services/auth.service';
import {MsgGeralHttpService} from '../../../../services/http/msg-geral-http.service';
import {VariadosService} from '../../../../services/variados.service';

@Component({
    selector: 'app-msg-geral-c',
    templateUrl: './msg-geral-c.component.html',
    styleUrls: ['./msg-geral-c.component.css']
})
export class MsgGeralCComponent implements OnInit {

    formData: FormGroup;
    dados = [];

    constructor(private fb: FormBuilder,
                private notifyMsg: NotifyMessageService,
                private _http: MsgGeralHttpService,
                public variados: VariadosService,
                public authService: AuthService) {

    }

    ids = {
        gerenciadorId: this.authService.profile.id,
    };

    ngOnInit() {
        this.formFields();
        this.listarDados();
    }

    listarDados() {
        this._http.index().subscribe((resp) => {
            this.dados = resp.data;
        });
    }

    onSubmitInsert() {
        if (this.formData.valid) {
            this._http.store(this.formData.value)
                .subscribe(() => {
                    this.formData.reset({
                        status: {value: true, disabled: false},
                        users_id: {value: this.ids.gerenciadorId, disabled: false}
                    });
                    this.listarDados();
                }, error => this.notifyMsg.error(error.error.message));
        } else {
            this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
        }
    }

    private formFields() {
        /**
         * dados que vem do formuláio html
         */
        this.formData = this.fb.group({
            mensagem: [null, [Validators.required]],
            status: [true, [Validators.required]],
            users_id: [this.ids.gerenciadorId, [Validators.required]]
        });
    }

    delMsg(id_base64: any) {
        this._http.destroy(id_base64).subscribe(() => {
            this.listarDados();
            this.notifyMsg.success('Mensagem removida');
        });
    }
}
