import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {ProgramacaoHttpService} from '../../../../services/http/programacao-http.service';
import {BsLocaleService} from 'ngx-bootstrap';

@Component({
  selector: 'app-programacao-modal-e',
  templateUrl: './programacao-modal-e.component.html',
  styleUrls: ['./programacao-modal-e.component.css']
})
export class ProgramacaoModalEComponent implements OnInit {

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  formData: FormGroup;
  _registroId: string;

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this._http.show(value).subscribe((resp) => {
          this.formData.patchValue(resp.data);
        }
      );
    }
  }

  constructor(private fb: FormBuilder,
              private _http: ProgramacaoHttpService,
              private notifyMsg: NotifyMessageService,
              private datepicker: BsLocaleService) {
    datepicker.use('pt-BR');
  }

  ngOnInit() {
    this.formFields();
  }

  onSubmitUpdate() {
    this._http.update(this._registroId, this.formData.value)
      .subscribe(() => {
        this.modal.hide();
        this.onSuccess.emit();
      }, error => this.notifyMsg.error(error.error.message));
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    this.formData = this.fb.group({
      d_data: [null, [Validators.required]],
      descricao: [null, [Validators.required]]
    });
  }
}
