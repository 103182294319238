import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AuthService} from '../../../../services/auth.service';
import {EnsaioProficienciaHttpService} from '../../../../services/http/ensaio-proficiencia-http.service';
import {EnsaioProficienciaModalEComponent} from '../ensaio-proficiencia-modal-e/ensaio-proficiencia-modal-e.component';
import {EnsaioProficienciaModalDComponent} from '../ensaio-proficiencia-modal-d/ensaio-proficiencia-modal-d.component';

@Component({
  selector: 'app-ensaio-proficiencia-c',
  templateUrl: './ensaio-proficiencia-c.component.html',
  styleUrls: ['./ensaio-proficiencia-c.component.css']
})
export class EnsaioProficienciaCComponent implements OnInit {

  dados = [];
  formData: FormGroup;
  registroId: string;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(EnsaioProficienciaModalEComponent) modalE: EnsaioProficienciaModalEComponent;
  @ViewChild(EnsaioProficienciaModalDComponent) modalD: EnsaioProficienciaModalDComponent;

  constructor(private fb: FormBuilder,
              private _http: EnsaioProficienciaHttpService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService) {
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  ngOnInit() {
    this.listarDados();
    this.formFields();
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarDados();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarDados() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.dados = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  private formFields() {
    this.formData = this.fb.group({
      ensaio: [null, [Validators.required]],
      ano1: [null],
      ano2: [null],
      ano3: [null],
      ano4: [null],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluido com sucesso.');
  }
}
