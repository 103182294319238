import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {environment} from '../../environments/environment';

const TOKEN_KEY = 'iqcontroller_token';
const TOKEN_EMPRESA = 'iqcontroller_empresa';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = `${environment.api.url}`;

  profile = null;//pegar dados do usuario logado

  constructor(private _http: HttpClient) {
    const token = this.getToken();
    this.setUserFromToken(token);
  }

  login(user: { email: string, password: string }): Observable<{ token: string }> {
    return this._http
      .post<{ token: string }>(`${this.baseUrl}/login`, user).pipe(
        tap(response => {
          this.setToken(response.token);
        })
      );
  }

  setToken(token: string) {
    this.setUserFromToken(token);
    token ? window.localStorage.setItem(TOKEN_KEY, token) : window.localStorage.removeItem(TOKEN_KEY);
  }

  getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  /*
  Exclusivo para trabalhar com a escolha da empresa
   */
  setEmpresa(token: string) {
    token ? window.localStorage.setItem(TOKEN_EMPRESA, token) : window.localStorage.removeItem(TOKEN_EMPRESA);
  }

  getEmpresa(): string | null {
    return window.localStorage.getItem(TOKEN_EMPRESA);
  }


  /*
      Convertendo o tokem via base64 e pegando o usuario logado
   */
  private setUserFromToken(token: string) {
    const payload = new JwtHelperService().decodeToken(token);
    this.profile = payload ? {
      id: payload.sub,
      name: payload.name,
      email: payload.email,
      nivel: payload.nivel,
      empresa_id: payload.empresa_id,
      status: payload.status
    } : null;
  }

  /*
  Responsavel por verificar se o token é valido
   */
  isAuth(): boolean {
    const token = this.getToken();

    /*o offsetSeconds tem q ser o mesmo lar jwt.php (JWT_BLACKLIST_GRACE_PERIOD ou .env)*/
    return !new JwtHelperService().isTokenExpired(token, 30);
  }

  /*
    sair do sistema
   */
  logout(): Observable<any> {
    return this._http
      .post<{ token: string }>(`${this.baseUrl}/logout`, {})
      .pipe(
        tap(() => {
          this.setToken(null);
          this.setEmpresa(null);
        })
      );
  }

  /*
    verifica se é master ou menot, e faz a liberações menu e editar/excluir
    https://www.tutorialspoint.com/typescript/typescript_if_else_statement.htm
    https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Operadores_Logicos
   */
  libMasterMentorConsultor() {
    return (this.profile.nivel === '1' || this.profile.nivel === '2' || this.profile.nivel === '3') ? true : false;
  }

  libMasterMentor() {
    return (this.profile.nivel === '1' || this.profile.nivel === '2') ? true : false;
  }

  libMaster() {
    return (this.profile.nivel === '1') ? true : false;
  }

  libMentor() {
    return (this.profile.nivel === '2') ? true : false;
  }

  libConsultor() {
    return (this.profile.nivel === '3') ? true : false;
  }

  libCliente() {
    return (this.profile.nivel === '4') ? true : false;
  }

}
