import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MultiAnexosHttpService} from '../../../../services/http/multi-anexos-http.service';
import {AuthService} from '../../../../services/auth.service';
import {ModalXlComponent} from '../../../bootstrap/modal-xl/modal-xl.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotifyMessageService} from '../../../../services/notify-message.service';

@Component({
    selector: 'app-anexo-valido-md',
    templateUrl: './anexo-valido-md.component.html',
    styleUrls: ['./anexo-valido-md.component.css']
})
export class AnexoValidoMdComponent implements OnInit {
    _registroId: string;
    _titleModal: string;

    dados = [];

    @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();
    @ViewChild(ModalXlComponent) modal: ModalXlComponent;

    @Input()
    set registroId(value) {
        if (value !== undefined) {
            this._http.filterValidoPorGrupo(this.authService.getEmpresa(), value).subscribe((resp) => {
                if (resp.data.length > 0) {
                    this.dados = resp.data;
                } else {
                    this.dados = null;
                }
            });
        }
    }

    @Input()
    set titleModal(value) {
        this._titleModal = value;
    }

    constructor(public _http: MultiAnexosHttpService,
                private authService: AuthService,
                private spinnerService: NgxSpinnerService,
                private notifyMsg: NotifyMessageService) {
    }

    ngOnInit() {
        // this._http.filterValidoPorGrupo(this.authService.getEmpresa(), this._registroId).subscribe((resp) => {
        //   this.dados = resp.data;
        // });
    }

    showModal() {
        this.modal.show();
    }

    deletarDocValido(id_base64: any) {
        //console.log(id_base64);
        this.spinnerService.show();
        this.modal.hide();
        this._http.destroy(id_base64).subscribe(() => {
            window.location.reload();
            this.spinnerService.hide();
            this.onSuccess.emit();
        }, error => this.notifyMsg.error(error.error.message));
    }

}
