import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsLocaleService} from 'ngx-bootstrap';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {CalibracaoVerificacaoHttpService} from '../../../../services/http/calibracao-verificacao-http.service';
import {AuthService} from '../../../../services/auth.service';

declare var $: any;

@Component({
  selector: 'app-calibracao-verificacao-modal-c',
  templateUrl: './calibracao-verificacao-modal-c.component.html',
  styleUrls: ['./calibracao-verificacao-modal-c.component.css']
})
export class CalibracaoVerificacaoModalCComponent implements OnInit {

  /*decorador de eventos p/ ser pego nos components filhos*/
  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  formData: FormGroup;
  _registroId: string;

  /*decorador de eventos p/ enviar dados dos components filhos para o pai*/
  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this._http.show(value).subscribe((resp) => {
          this.formData.patchValue(resp.data);
        }
      );
    }
  }

  constructor(private fb: FormBuilder,
              private datepicker: BsLocaleService,
              private notifyMsg: NotifyMessageService,
              private _http: CalibracaoVerificacaoHttpService,
              private authService: AuthService) {
    datepicker.use('pt-BR');
  }

  ngOnInit() {
    $(function () {
      $('#d_vencimento').mask('00/00/0000');
    });

    this.formFields();
  }

  onSubmitUpdate() {
    this._http.update(this._registroId, this.formData.value)
      .subscribe(() => {
        this.modal.hide();
        this.onSuccess.emit();
      }, error => this.notifyMsg.error(error.error.message));
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    this.formData = this.fb.group({
      codigo: [null],
      equipamento: [null, [Validators.required]],
      frequencia: [null, [Validators.required]],
      vencimento: [null, [Validators.required]]
    });
  }
}
