import {Component, OnInit, ViewChild} from '@angular/core';
import {DocumentacaoPessoalHttpService} from '../../../../services/http/documentacao-pessoal-http.service';
import {AuthService} from '../../../../services/auth.service';
import {AnexoDocExibirMdComponent} from '../anexo-doc-exibir-md/anexo-doc-exibir-md.component';

@Component({
  selector: 'app-documentacao-pessoal',
  templateUrl: './documentacao-pessoal.component.html',
  styleUrls: ['./documentacao-pessoal.component.css']
})
export class DocumentacaoPessoalComponent implements OnInit {

  dados = [];
  pessoaId: string; // pegar o id da pessao

  @ViewChild(AnexoDocExibirMdComponent) mdExibir: AnexoDocExibirMdComponent;

  constructor(private _http: DocumentacaoPessoalHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

  showArquivo(id) {
    this.pessoaId = id;
    this.mdExibir.showModal();
  }
}
