import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {BsLocaleService} from 'ngx-bootstrap';
import {AuthService} from '../../../../services/auth.service';
import {DocumentacaoPessoalHttpService} from '../../../../services/http/documentacao-pessoal-http.service';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';
import {DocumentacaoPessoalModalEComponent} from '../documentacao-pessoal-modal-e/documentacao-pessoal-modal-e.component';
import {DocumentacaoPessoalModalDComponent} from '../documentacao-pessoal-modal-d/documentacao-pessoal-modal-d.component';
import {AnexoDocCurriculoPsMdComponent} from '../anexo-doc-curriculo-ps-md/anexo-doc-curriculo-ps-md.component';
import {AnexoDocContratoPsMdComponent} from '../anexo-doc-contrato-ps-md/anexo-doc-contrato-ps-md.component';
import {AnexoDocCnhPsMdComponent} from '../anexo-doc-cnh-ps-md/anexo-doc-cnh-ps-md.component';
import {AnexoDocCreaCarteiraPsMdComponent} from '../anexo-doc-crea-carteira-ps-md/anexo-doc-crea-carteira-ps-md.component';
import {AnexoDocCreaCertidaoPsMdComponent} from '../anexo-doc-crea-certidao-ps-md/anexo-doc-crea-certidao-ps-md.component';
import {AnexoDocImparcialidadePsMdComponent} from '../anexo-doc-imparcialidade-ps-md/anexo-doc-imparcialidade-ps-md.component';
import {AnexoDocHabilitacaoPsMdComponent} from '../anexo-doc-habilitacao-ps-md/anexo-doc-habilitacao-ps-md.component';
import {AnexoDocCondutaPsMdComponent} from '../anexo-doc-conduta-ps-md/anexo-doc-conduta-ps-md.component';
import {AnexoDocEpiPsMdComponent} from '../anexo-doc-epi-ps-md/anexo-doc-epi-ps-md.component';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AnexoDocExibirMdComponent} from '../anexo-doc-exibir-md/anexo-doc-exibir-md.component';

declare var $: any;

@Component({
    selector: 'app-documentacao-pessoal-c',
    templateUrl: './documentacao-pessoal-c.component.html',
    styleUrls: ['./documentacao-pessoal-c.component.css']
})
export class DocumentacaoPessoalCComponent implements OnInit {

    formData: FormGroup;
    dados = [];
    dadosPessoal: Observable<Array<any>>; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
    registroId: string;
    pessoaId: string; // pegar o id da pessao
    pessoaAnexoId: string;// pegar id do registro de documento


    /*com o @ViewChild pego a instância do modal pai ou filhos*/
    @ViewChild(DocumentacaoPessoalModalEComponent) modalE: DocumentacaoPessoalModalEComponent;
    @ViewChild(DocumentacaoPessoalModalDComponent) modalD: DocumentacaoPessoalModalDComponent;

    @ViewChild(AnexoDocCurriculoPsMdComponent) mdAnexoA: AnexoDocCurriculoPsMdComponent;
    @ViewChild(AnexoDocContratoPsMdComponent) mdAnexoB: AnexoDocContratoPsMdComponent;
    @ViewChild(AnexoDocCnhPsMdComponent) mdAnexoC: AnexoDocCnhPsMdComponent;
    @ViewChild(AnexoDocCreaCarteiraPsMdComponent) mdAnexoD: AnexoDocCreaCarteiraPsMdComponent;
    @ViewChild(AnexoDocCreaCertidaoPsMdComponent) mdAnexoE: AnexoDocCreaCertidaoPsMdComponent;
    @ViewChild(AnexoDocImparcialidadePsMdComponent) mdAnexoF: AnexoDocImparcialidadePsMdComponent;
    @ViewChild(AnexoDocHabilitacaoPsMdComponent) mdAnexoG: AnexoDocHabilitacaoPsMdComponent;
    @ViewChild(AnexoDocCondutaPsMdComponent) mdAnexoH: AnexoDocCondutaPsMdComponent;
    @ViewChild(AnexoDocEpiPsMdComponent) mdAnexoI: AnexoDocEpiPsMdComponent;

    @ViewChild(AnexoDocExibirMdComponent) mdExibir: AnexoDocExibirMdComponent;

    constructor(private fb: FormBuilder,
                private _http: DocumentacaoPessoalHttpService,
                private _httpPessoal: PessoalHttpService,
                private notifyMsg: NotifyMessageService,
                private datepicker: BsLocaleService,
                private authService: AuthService) {
        datepicker.use('pt-BR');
    }

    ids = {
        empresaId: this.authService.getEmpresa(),
        gerenciadorId: this.authService.profile.id,
    };

    ngOnInit() {
        $(function () {
            $('#d_aso').mask('00/00/0000');
        });

        this.listarDados();
        this.dadosPessoal = this._httpPessoal.index().pipe(map(resp => resp.data));
        this.formFields();
    }

    onSubmitInsert() {
        if (this.formData.valid) {
            this._http.store(this.formData.value)
                .subscribe(() => {
                    this.formData.reset({
                            /*limpa todos os outros inputs, menos os listados abaixo*/
                            empresa_id: {value: this.ids.empresaId, disabled: false},
                            users_id: {value: this.ids.gerenciadorId, disabled: false}
                        }
                    );
                    this.notifyMsg.success('Registro incluido com sucesso.');
                    this.listarDados();
                }, error => this.notifyMsg.error(error.error.message));
        } else {
            this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
        }
    }

    listarDados() {
        this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => this.dados = resp.data);
    }

    showEdit(id_base64: any) {
        this.registroId = atob(id_base64);
        this.modalE.showModal();
    }

    showDel(id_base64: string) {
        this.registroId = atob(id_base64);
        this.modalD.showModal();
        // this._http.destroy(atob(id_base64)).subscribe(() => {
        //   this.notifyMsg.success('Registro excluído com sucesso.');
        //   this.listarDados();
        // }, error => this.notifyMsg.error(error.error.message));
    }

    showAnexoA(id_base64: any) {
        //Currículo
        this.pessoaAnexoId = id_base64;
        this.mdAnexoA.showModal();
    }

    showAnexoB(id_base64: any) {
        //Contrato
        this.pessoaAnexoId = id_base64;
        this.mdAnexoB.showModal();
    }

    showAnexoC(id_base64: any) {
        //CNH/RG
        this.pessoaAnexoId = id_base64;
        this.mdAnexoC.showModal();
    }

    showAnexoD(id_base64: any) {
        //Carteira
        this.pessoaAnexoId = id_base64;
        this.mdAnexoD.showModal();
    }

    showAnexoE(id_base64: any) {
        //Certidão
        this.pessoaAnexoId = id_base64;
        this.mdAnexoE.showModal();
    }

    showAnexoF(id_base64: any) {
        //Imparcialidade
        this.pessoaAnexoId = id_base64;
        this.mdAnexoF.showModal();
    }

    showAnexoG(id_base64: any) {
        //Habilitação
        this.pessoaAnexoId = id_base64;
        this.mdAnexoG.showModal();
    }

    showAnexoH(id_base64: any) {
        //Conduta
        this.pessoaAnexoId = id_base64;
        this.mdAnexoH.showModal();
    }

    showAnexoI(id_base64: any) {
        //EPI
        this.pessoaAnexoId = id_base64;
        this.mdAnexoI.showModal();
    }

    showArquivo(pessoal_id: any) {
        this.pessoaId = pessoal_id;
        this.mdExibir.showModal();
    }

    private formFields() {
        this.formData = this.fb.group({
            curriculo: [null, [Validators.required]],
            contrato: [null, [Validators.required]],
            // crea_carteira: [null, [Validators.required]],
            // d_cnh_rg: [null],
            tipo_rg_cnh: [null],
            d_vencimento_rg_cnh: [null],
            d_crea_certidao: [null],
            d_aso: [null],
            imparcialidade: [null, [Validators.required]],
            habilitacao: [null, [Validators.required]],
            conduta: [null, [Validators.required]],
            epi: [null, [Validators.required]],
            pessoal_id: [null, [Validators.required]],
            empresa_id: [this.ids.empresaId, [Validators.required]],
            users_id: [this.ids.gerenciadorId, [Validators.required]]
        });
    }

    onSuccessUpdate($event: any) {
        this.listarDados();
        this.notifyMsg.success('Registro atualizado com sucesso.');
    }

    onSuccessDelete($event: any) {
        this.listarDados();
        this.notifyMsg.success('Registro excluído com sucesso.');
    }

    /*comobox responsavel*/
    getPessoaId(id) {
        this.pessoaId = id.value;
    }

    onSuccessAnexoDelete($event: any) {
        this.notifyMsg.success('Anexo excluído com sucesso.');
    }
}
