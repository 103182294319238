import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MovimentoMsgHttpService {

    search: string;
    private baseUrl = `${environment.api.url}/movimento_geral_msg`;

    constructor(private http: HttpClient) {
    }

    /* exclusivo para busca*/
    listSearch(search): Observable<any> {
        const params = new HttpParams({
            fromObject: ({search: search ? search : ''})
        });
        return this.http.get(this.baseUrl, {params});
    }

    index(): Observable<any> {
        return this.http.get(this.baseUrl);
    }

    show(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    store(data): Observable<any> {
        return this.http.post(this.baseUrl, data);
    }

    update(id: string, data): Observable<any> {
        return this.http.put(`${this.baseUrl}/${id}`, data);
    }

    destroy(id: string): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${id}`);
    }

    verifcarMovimento(empresaId: string, msgGeralId: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/verifcar/${empresaId}/${msgGeralId}`);
    }
}
