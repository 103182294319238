import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { ModalLgComponent } from "../../../bootstrap/modal-lg/modal-lg.component";
import { MultiAnexosHttpService } from "../../../../services/http/multi-anexos-http.service";
import { PessoalHttpService } from "../../../../services/http/pessoal-http.service";
import { NotifyMessageService } from "../../../../services/notify-message.service";
import { BsLocaleService } from "ngx-bootstrap";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: "app-multi-anexos-invalidados-md-e",
  templateUrl: "./multi-anexos-invalidados-md-e.component.html",
  styleUrls: ["./multi-anexos-invalidados-md-e.component.css"],
})
export class MultiAnexosInvalidadosMdEComponent implements OnInit {
  formData: FormGroup;
  _registroId: string;
  dados = <any>[];
  dadosPessoal: Observable<
    Array<any>
  >; /* fiz direto, pois estou usando o pipe async lá na comobox, uma boa pratica*/
  pessoaId: string;

  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();
  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  constructor(
    private fb: FormBuilder,
    private _http: MultiAnexosHttpService,
    private _httpPessoal: PessoalHttpService,
    private authService: AuthService,
    private notifyMsg: NotifyMessageService,
    private datepicker: BsLocaleService
  ) {
    datepicker.use("pt-BR");
  }

  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this._http.show(value).subscribe((resp) => {
        this.formData.patchValue(resp.data);
      });
    }
  }

  ngOnInit() {
    this.formFields();
    this.dadosPessoal = this._httpPessoal
      .index()
      .pipe(map((resp) => resp.data));
  }

  onSubmitUpdate() {
    // fiz dessa forma p/ pegar o resposanvel_id, lá em formFields não dá certo
    this.formData.patchValue({ resposanvel_id: this.authService.profile.id });
    this._http.update(this._registroId, this.formData.value).subscribe(
      () => {
        this.modal.hide();
        this.onSuccess.emit();
      },
      (error) => this.notifyMsg.error(error.error.message)
    );
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    this.formData = this.fb.group({
      file_name_original: [null, [Validators.required]],
      status: [null, [Validators.required]],
      data_validade: [null, [Validators.required]],
      observacao: [null, [Validators.required]],
      observacao_organismo: [null, [Validators.required]],
      resposanvel_id: [this.authService.profile.id, [Validators.required]],
    });
  }

  /*comobox responsavel*/
  getPessoaId(id) {
    this.pessoaId = id.value;
  }
}
