import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsLocaleService} from 'ngx-bootstrap';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {AuthService} from '../../../../services/auth.service';
import {AnexosEmpresaHttpService} from '../../../../services/http/anexos-empresa-http.service';
import {NgxSpinnerService} from 'ngx-spinner';

declare var $: any;

@Component({
  selector: 'app-add-anexo-empresa',
  templateUrl: './add-anexo-empresa.component.html',
  styleUrls: ['./add-anexo-empresa.component.css']
})
export class AddAnexoEmpresaComponent implements OnInit {
  formData: FormGroup;

  dados = [];
  dadosPorEmpresa = [];

  constructor(private fb: FormBuilder,
              private datepicker: BsLocaleService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService,
              private _httpAnexo: AnexosEmpresaHttpService,
              private spinnerService: NgxSpinnerService) {
    datepicker.use('pt-BR');
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  ngOnInit() {
    $(function () {
      $('#data').mask('00/00/0000');
    });
    //this.listarDados();
    this.listarDadosPorEmpresa();
  }

  listarDados() {
    this._httpAnexo.index().subscribe((resp) => {
      this.dados = resp.data;
    });
  }

  listarDadosPorEmpresa() {
    this._httpAnexo.showporempresa(this.ids.empresaId).subscribe((resp) => {
      this.dadosPorEmpresa = resp.data;
    });
  }

  uploadAnexos(files: FileList) {
    if (!files.length) {
      return;
    }
    this.spinnerService.show();
    this._httpAnexo.create(this.ids.empresaId, this.ids.gerenciadorId, files).subscribe(() => {
      this.spinnerService.hide();
      this.listarDadosPorEmpresa();
      this.notifyMsg.success('Documento enviado com sucesso!');
    }, error => {
      this.spinnerService.hide();
      this.notifyMsg.error(error.error.message);
    });
  }

  showDelete(id_base64: any) {
    //console.log(id_base64);
  }
}

/*
      const formData = new FormData();
      formData.append('tipo_documento', this.formData.get('tipo_documento').value);
      formData.append('file_name', this.formData.get('file_name').value);
 */
