import {Component, OnInit, ViewChild} from '@angular/core';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';
import {PessoalModalEComponent} from '../pessoal-modal-e/pessoal-modal-e.component';
import {VariadosService} from '../../../../services/variados.service';
import {PessoalModalDComponent} from '../pessoal-modal-d/pessoal-modal-d.component';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-pessoal-index',
  templateUrl: './pessoal-index.component.html',
  styleUrls: ['./pessoal-index.component.css']
})
export class PessoalIndexComponent implements OnInit {

  pessoas = [];

  /*apenas p/ edição*/
  registroId: string;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(PessoalModalEComponent) modalE: PessoalModalEComponent;
  @ViewChild(PessoalModalDComponent) modalD: PessoalModalDComponent;

  constructor(private _http: PessoalHttpService,
              private notifyMsg: NotifyMessageService,
              public variados: VariadosService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.listData();
  }

  listData() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.pessoas = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listData();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  onSuccessUpdate($event: any) {
    this.listData();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listData();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }
}
