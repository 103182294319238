import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {PendenciaHttpService} from '../../../../services/http/pendencia-http.service';
import {AuthService} from '../../../../services/auth.service';
import {PendenciaModalEComponent} from '../pendencia-modal-e/pendencia-modal-e.component';
import {PendenciaModalDComponent} from '../pendencia-modal-d/pendencia-modal-d.component';
import {BsLocaleService} from 'ngx-bootstrap';

@Component({
  selector: 'app-pendencia-c',
  templateUrl: './pendencia-c.component.html',
  styleUrls: ['./pendencia-c.component.css']
})
export class PendenciaCComponent implements OnInit {

  pendencias = [];
  formData: FormGroup;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(PendenciaModalEComponent) pendenciaModalE: PendenciaModalEComponent;
  @ViewChild(PendenciaModalDComponent) modalD: PendenciaModalDComponent;

  constructor(private fb: FormBuilder,
              private _http: PendenciaHttpService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService,
              private datepicker: BsLocaleService) {
    datepicker.use('pt-BR');
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  /*apenas p/ ediação*/
  registroId: string;

  ngOnInit() {
    this.listarDados();
    this.formFields();
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarDados();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarDados() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id).subscribe((resp) => {
      this.pendencias = resp.data;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.pendenciaModalE.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }


  private formFields() {
    this.formData = this.fb.group({
      descricao: [null, [Validators.required]],
      d_data: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }
}
