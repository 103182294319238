import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {GruposHttpService} from '../../../../services/http/grupos-http.service';
import {AuthService} from '../../../../services/auth.service';
import {GruposModalEComponent} from '../grupos-modal-e/grupos-modal-e.component';
import {GruposModalDComponent} from '../grupos-modal-d/grupos-modal-d.component';

declare var $: any;

@Component({
  selector: 'app-grupos-c',
  templateUrl: './grupos-c.component.html',
  styleUrls: ['./grupos-c.component.css']
})
export class GruposCComponent implements OnInit {

  dados = [];
  formData: FormGroup;

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(GruposModalEComponent) modalUpdate: GruposModalEComponent;
  @ViewChild(GruposModalDComponent) modalD: GruposModalDComponent;

  constructor(private fb: FormBuilder,
              private _http: GruposHttpService,
              private notifyMsg: NotifyMessageService,
              private authService: AuthService) {
  }

  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  /*apenas p/ edição*/
  registroId: string;

  ngOnInit() {
    this.listarDados();
    this.formFields();
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset({
              /*limpa todos os outros inputs, menos os listados abaixo*/
              empresa_id: {value: this.ids.empresaId, disabled: false},
              users_id: {value: this.ids.gerenciadorId, disabled: false}
            }
          );
          this.notifyMsg.success('Registro incluido com sucesso.');
          this.listarDados();
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  listarDados() {
    this._http.index().subscribe((resp) => {
      this.dados = resp;
    });
  }

  showEdit(id_base64: any) {
    this.registroId = atob(id_base64);
    this.modalUpdate.showModal();
  }

  showDel(id_base64: string) {
    this.registroId = atob(id_base64);
    this.modalD.showModal();
    // this._http.destroy(atob(id_base64)).subscribe(() => {
    //   this.notifyMsg.success('Registro excluído com sucesso.');
    //   this.listarDados();
    // }, error => this.notifyMsg.error(error.error.message));
  }

  private formFields() {
    this.formData = this.fb.group({
      descricao: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

  onSuccessUpdate($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro atualizado com sucesso.');
  }

  onSuccessDelete($event: any) {
    this.listarDados();
    this.notifyMsg.success('Registro excluído com sucesso.');
  }
}
