import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FileUploadService} from '../file-upload.service';

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.css']
})
export class UploadsComponent implements OnInit {

  profileForm: FormGroup;
  error: string;

  fileUpload = {status: '', message: '', filePath: ''};

  constructor(private fb: FormBuilder,
              private fileUploadService: FileUploadService) {
  }

  ngOnInit() {
    this.profileForm = this.fb.group({
      name: [''],
      data_emissao: [''],
      data_validade: [''],
      nome_documento: [''],
      file_name: ['']
    });
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get('file_name').setValue(file);
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('name', this.profileForm.get('name').value);
    formData.append('data_emissao', this.profileForm.get('data_emissao').value);
    formData.append('data_validade', this.profileForm.get('data_validade').value);
    formData.append('nome_documento', this.profileForm.get('nome_documento').value);
    formData.append('file_name', this.profileForm.get('file_name').value);

    this.fileUploadService.upload(formData).subscribe(
      res => this.fileUpload = res,
      err => this.error = err
    );
  }

}
