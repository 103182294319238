import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalLgComponent} from '../../../bootstrap/modal-lg/modal-lg.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {BsLocaleService} from 'ngx-bootstrap';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';

declare var $: any;

@Component({
  selector: 'app-pessoal-modal-e',
  templateUrl: './pessoal-modal-e.component.html',
  styleUrls: ['./pessoal-modal-e.component.css']
})
export class PessoalModalEComponent implements OnInit {

  /*decorador de eventos p/ ser pego nos components filhos*/
  @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

  /*com o @ViewChild pego a instância do modal pai ou filhos*/
  @ViewChild(ModalLgComponent) modal: ModalLgComponent;

  formData: FormGroup;
  _registroId: string;

  /*decorador de eventos p/ enviar dados dos components filhos para o pai*/
  // @Input()
  //_registroId: string;

  /*decorador de eventos p/ enviar dados dos components filhos para o pai*/
  @Input()
  set registroId(value) {
    if (value !== undefined) {
      this._registroId = value;
      this._http.show(value).subscribe((resp) => {
          this.formData.patchValue(resp.data);
        }
      );
    }
  }

  constructor(private fb: FormBuilder,
              private datepicker: BsLocaleService,
              private notifyMsg: NotifyMessageService,
              private _http: PessoalHttpService) {
    datepicker.use('pt-BR');
  }

  ngOnInit() {
    $(function () {
      $('#cpf').mask('000.000.000-00');
      $('#admissao, #cnh_validade').mask('00/00/0000');
      $('#cnh').mask('99999999999');
      $('#telefone').mask('(99)9999-9999');
      $('#celular').mask('99999-9999');
    });
    this.formFields();
  }

  onSubmitUpdate() {
    this._http.update(this._registroId, this.formData.value)
      .subscribe(() => {
        this.modal.hide();
        this.onSuccess.emit();
      }, error => this.notifyMsg.error(error.error.message));
  }

  showModal() {
    this.modal.show();
  }

  private formFields() {
    /**
     * dados que vem do formuláio html
     */
    this.formData = this.fb.group({
      cpf: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      crea_cft: [null],
      cnh: [null],
      cnh_validade: [null],
      cnh_categoria: [null],
      admissao: [null, [Validators.required]],
      formacao: [null, [Validators.required]],
      cargo: [null, [Validators.required]],
      vinculo: [null, [Validators.required]]
    });
  }

}
