import {Component, OnInit} from '@angular/core';
import pace from 'pace';
import {AuthService} from './services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(public authService: AuthService,
              private spinnerService: NgxSpinnerService,
              router: Router) {
                const navEndEvents = router.events.pipe(
                  filter(event => event instanceof NavigationEnd),
                );

                navEndEvents.subscribe((event: NavigationEnd) => {
                      gtag('config', 'UA-203921819-1', {
                        'page_path': event.urlAfterRedirects
                      });
                })
  }

  ngOnInit() {
    pace.start({
      document: false
    });

    this.spinner();
  }

  canShowNavbar() {
    return this.authService.isAuth();
  }

  private spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 1000);
  }
}
