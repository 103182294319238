import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {ModalComponent} from "../../../bootstrap/modal/modal.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AjudaHttpService} from "../../../../services/http/ajuda-http.service";
import {NotifyMessageService} from "../../../../services/notify-message.service";
import {AuthService} from "../../../../services/auth.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
    selector: 'app-ajuda-md',
    templateUrl: './ajuda-md.component.html',
    styleUrls: ['./ajuda-md.component.css']
})
export class AjudaMdComponent implements OnInit {

    formData: FormGroup;

    @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() onError: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

    @ViewChild(ModalComponent) modal: ModalComponent;

    constructor(private fb: FormBuilder,
                private _http: AjudaHttpService,
                private notifyMsg: NotifyMessageService,
                private spinnerService: NgxSpinnerService) {
    }

    ngOnInit() {
        this.formFields();
    }

    onSubmitInsert() {
        if (this.formData.valid) {
            this.spinnerService.show();
            this.modal.hide();
            this._http.store(this.formData.value)
                .subscribe(() => {
                    this.formData.reset();
                    this.spinnerService.hide();
                    this.notifyMsg.success('Mensagem enviada com sucesso.');
                }, error => {
                    this.notifyMsg.error(error.error.message);
                    this.spinnerService.hide();
                });
        } else {
            this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
        }
    }

    private formFields() {
        this.formData = this.fb.group({
            nome_completo: [null, [Validators.required]],
            email: [null, [Validators.required]],
            mensagem: [null, [Validators.required]]
        });
    }

    showModal() {
        this.modal.show();
    }

}
