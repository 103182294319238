import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsLocaleService} from 'ngx-bootstrap';
import {NotifyMessageService} from '../../../../services/notify-message.service';
import {PessoalHttpService} from '../../../../services/http/pessoal-http.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';

declare var $: any;

@Component({
  selector: 'app-pessoal-create',
  templateUrl: './pessoal-create.component.html',
  styleUrls: ['./pessoal-create.component.css']
})
export class PessoalCreateComponent implements OnInit {

  formData: FormGroup;

  constructor(private fb: FormBuilder,
              private datepicker: BsLocaleService,
              private notifyMsg: NotifyMessageService,
              private _http: PessoalHttpService,
              private router: Router,
              private authService: AuthService) {
    datepicker.use('pt-BR');
  }

  /*falta imprementar lá no formDate*/
  ids = {
    empresaId: this.authService.getEmpresa(),
    gerenciadorId: this.authService.profile.id,
  };

  ngOnInit() {
    $(function () {
      $('#cpf').mask('000.000.000-00');
      $('#admissao, #cnh_validade').mask('00/00/0000');
      $('#cnh').mask('99999999999');
      $('#telefone').mask('(99)9999-9999');
      $('#celular').mask('99999-9999');
    });
    this.formFields();
  }

  onSubmitInsert() {
    if (this.formData.valid) {
      this._http.store(this.formData.value)
        .subscribe(() => {
          this.formData.reset();
          this.notifyMsg.success('Registro incluido com sucesso.');
          return this.router.navigate(['/pessoal']);
        }, error => this.notifyMsg.error(error.error.message));
    } else {
      this.notifyMsg.info('Por favor preencha os campos obrigatórios.');
    }
  }

  private formFields() {
    /**
     * dados que vem do formuláio html
     */
    this.formData = this.fb.group({
      cpf: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      crea_cft: [null],
      cnh: [null],
      cnh_validade: [null],
      cnh_categoria: [null],
      admissao: [null, [Validators.required]],
      formacao: [null, [Validators.required]],
      cargo: [null, [Validators.required]],
      vinculo: [null, [Validators.required]],
      empresa_id: [this.ids.empresaId, [Validators.required]],
      users_id: [this.ids.gerenciadorId, [Validators.required]]
    });
  }

}
