import {Component, OnInit} from '@angular/core';
import {RetroalimentacaoHttpService} from '../../../../services/http/retroalimentacao-http.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-retroalimentacao',
  templateUrl: './retroalimentacao.component.html',
  styleUrls: ['./retroalimentacao.component.css']
})
export class RetroalimentacaoComponent implements OnInit {

  dados = [];

  constructor(private _http: RetroalimentacaoHttpService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this._http.filterCompanyUser(this.authService.getEmpresa(), this.authService.profile.id)
      .subscribe((resp) => this.dados = resp.data);
  }

}
