import {Component, OnInit} from '@angular/core';
import {MovimentoMsgHttpService} from '../../../../services/http/movimento-msg-http.service';

@Component({
    selector: 'app-logs-ciente',
    templateUrl: './logs-ciente.component.html',
    styleUrls: ['./logs-ciente.component.css']
})
export class LogsCienteComponent implements OnInit {

    dados = [];

    constructor(private _http: MovimentoMsgHttpService) {
    }

    ngOnInit() {
        this._http.index().subscribe((resp) => {
            this.dados = resp.data;
        });
    }

}
